#progress {
  background-color: rgba(255, 255, 255, 0.3);
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #96c130;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  position: absolute;
  top: calc(40% - 60px);
  left: calc(50% - 60px);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}