#catalog {
  width: 100%;
  height: 100%;
  overflow: auto;
  float: left;
  border: 1px solid lightgrey;
  padding: 5px;
  padding-top: 20px;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
#catalog::before {
  content: 'catalog';
  display: block;
  position: absolute;
  top: -1px;
  left: -1px;
  border: 1px solid lightgrey;
  padding: 0px 5px;
}
.catalog-item {
  width: 100px;
  height: 140px;
  border: 1px solid grey;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-around;
  align-items: center;
  margin: 5px;
  position: relative;
  z-index: 3;
}
/* create folder effect with before/after */
.catalog-item.folder::before {
  content: '';
  display: block;
  position: absolute;
  border: 1px solid grey;
  height: calc(100% - 3px);
  top: 1px;
  left: 100%;
  width: 1px;
  background-color: white;
}
.catalog-item.folder::after {
  content: '';
  display: block;
  position: absolute;
  border: 1px solid grey;
  height: calc(100% - 6px);
  top: 3px;
  left: 100%;
  z-index: -1;
  width: 3px;
}
.catalog-item-icon {
  max-width: 100%;
  max-height: 100%;
}
.catalog-item-label {
  text-overflow: ellipsis;
  overflow: hidden;
}

.catalog-search {
  position: absolute;
  top: 1px;
  left: 120px;
}
.catalog-search-input {
  width: 210px;
  margin-right: 1px;
}