#persistence {
  width: 350px;
  border: 1px solid lightgrey;
  padding: 5px;
  position: relative;
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  float: left;
  margin-top: 10px;
}
#persistence::before {
  content: 'persistence';
  display: block;
  position: absolute;
  top: -1px;
  left: -1px;
  border: 1px solid lightgrey;
  padding: 0px 5px;
}

.save {
  cursor: pointer;
}
.load {
  cursor: pointer;
}