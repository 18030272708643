
    @font-face{
        font-family:"Avenir LT W04_45 Book";
        src:url("./fonts/fe2d00d9-aadd-40f7-a144-22a6f695aa8f.woff2") format("woff2"),url("./fonts/c3795fc9-e264-4795-9a19-b57086aa6f7b.woff") format("woff");
    }
    @font-face{
        font-family:"Avenir LT W04_65 Medium";
        src:url("./fonts/7839a002-2019-46e4-8db8-c8335356ceee.woff2") format("woff2"),url("./fonts/28433854-a1b7-4a30-b6a4-10c3a5c75494.woff") format("woff");
    }
    @font-face{
        font-family:"Avenir LT W04_95 Black";
        src:url("./fonts/9816d7b9-dd57-4a56-8958-1a7dbbb8ad82.woff2") format("woff2"),url("./fonts/73f716bf-01e6-4cfe-a05b-b6e2f12a44e7.woff") format("woff");
    }


html, body {
  margin: 0;
  height: 100%;
  font-family: "Avenir LT W04_45 Book";
}
* {
  box-sizing: border-box;
}
#app-container {
  height: 100%;
  padding: 5px;
}

.header {
  width: 100%;
  height: 180px;
}

.main {
    margin: 0;
}
.footer {
  width: 100%;
  height: 250px;
}

/* Custom CSS */


#progress {
    background-color: rgba(255, 255, 255, 0.3);
    width: 100%;
    height: 100%;
    z-index: 999;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.property-choice-text-wrap {
    width: fit-content !important;
    font-family: "Avenir LT W04_45 Book";
    color: #0A0A0A;
    font-weight: 500;
    font-size: 19px;
    line-height: 23px;
    letter-spacing: -0.02em;
}

#viewer {
    width: 60%;
    margin: 0;
    height: 100vh;
                border: 0;
}

#property-editor {
    width: 40%;
    margin: 0;
    padding: 32px;
                border: 0;
				height: unset;
}

div#app-container {
    padding: 0;
}

.property-choice {
    display: flex;
    border: 1px solid #CAD2DB;
    padding: 24px;
   align-items: flex-start;
}

.property-choice::before {
    content: "";
    width: 16px;
    height: 16px;
    background: url(./images/selector_mt.svg);
    background-size: cover;
	transition: .15s ease;
}

.property-choice.selected::before {
    background: url(./images/selector_fl.svg);
}

.property-choice.selected:hover::before {
    background: url(./images/selector_fl.svg);
}

.property-choice:hover {
    border: 1px solid #002C3F;
}

.property-choice:hover::before {
    background: url(./images/selector_hv.svg);
}

.property-choice.selected {
    border: 2px solid #0092D1;
    padding: 23px;
    box-shadow: 0px 0px 0px 4px rgb(155 225 255);
}

.property-choice img {
    height: 40px;
    width: 40px;
    border-radius: 40px;
    max-width: 40px;
    padding: 4px;
    margin-right: 8px;
}
 
 
 .loader {
    border: 16px solid #F7F9FA;
    border-top: 16px solid #001620;
	top: calc(50% - 60px);
}

.property-choice {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.property-choice div {
    width: calc(100% - 88px);
}

.property-choice img {
    margin-left: 16px;
}


.property wrap {
    grid-column: 1 / -1;
}

#property-editor {
    background: #ffffff;
    float: right;
}

.property {
    background: transparent;
}

.property-class {
    background: transparent;
}


div#app-container .header {
    display: none;
}

html #viewer {
    position: fixed !important;
}


.property-header {
    grid-column: 1 / -1;
}

.property[data-choice-list="true"][data-editable="true"]::after {
    display: none;
}

.property .property-choices {
    animation: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
}

#prop-S420_360 .property-choice {
    grid-column: 1 / -1;
}

#viewer::before, #property-editor::before {
    display: none;
}

#viewer::before, #property-editor::before {
    display: none;
}

.property-header {
    font-size: 23px;
    line-height: 28px;
    font-family: "Avenir LT W04_65 Medium";
    color: #0A0A0A;
}


.property-title {
    text-transform: lowercase;
}

.property-subtitle {
    color: #00638E;
    font-size: 16px;
    line-height: 24px;
    font-family: "Avenir LT W04_45 Book";
}



.property-choice.selected:hover {
    pointer-events: none !important;
}

.property {
    margin-top: 32px;
}

.property[data-editable="false"] .property-title::before {
    content: "";
}

.property[data-editable="false"] {
    font-style: initial;
}

.property[data-editable="false"] .property-title {
    text-transform: initial;
}


.property-subtitle {
    display: none;
}

input.property-choice-toggle {
    width: 42px;
    height: 24px;
    display: block;
    position: relative;
}

input.property-choice-toggle::after {
    content: "";
    height: 20px;
    width: 20px;
    background: #fff;
    display: block;
    border-radius: 10px;
    position: absolute;
    top: 2px;
    left: 2px;
}

input.property-choice-toggle:checked::after {
    transform: translateX(18px);
}

input.property-choice-toggle::before {
    content: "";
    height: 24px;
    width: 42px;
    background: #E1E5EB;
    display: block;
    border-radius: 12px;
    transition: background .15s ease;
}

input.property-choice-toggle:hover::before {
    background: #232323;
}

input.property-choice-toggle:hover {
    cursor: pointer;
}

input.property-choice-toggle:checked::before {
    background: #10B981;
}

input.property-choice-toggle::after {
    transition: .3s ease;
}

.container-hub .property:nth-last-child(1), .container-hub .property:nth-last-child(2) {
    display: none;
}





#export {
    border: 0;
    padding: 0;
    animation: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    width: 100%;
    margin-top: 32px;
}

button.export-item {
    border: 1px solid #0A0A0A;
    padding: 24px;
    background: transparent;
    text-align: left;
    font-size: 18px;
    line-height: 40px;
    font-family: "Avenir LT W04_65 Medium";
    color: #0A0A0A;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transition: .15s ease;
    align-items: center;
}

#export::before {
    content: var(--translated-content-42, "Next steps ");
    font-size: 23px;
    line-height: 28px;
    font-family: "Avenir LT W04_65 Medium";
    color: #0A0A0A;
    border: 0;
    display: block;
    position: initial;
    grid-column: 1 / -1;
    padding: 0;
}

button.export-item::after {
    content: "";
    display: block;
    height: 24px;
    width: 24px;
    background: #0A0A0A;
    transition: .15s ease;
}

button.export-item:hover {
    background: #0A0A0A;
    color: #fff;
}

button.export-item:hover::after {
    background: #fff;
}

.property {
    cursor: default;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}







/*
.footer {
    height: 1px;
    overflow: hidden;
}*/
button.export-item:nth-child(4) {
    grid-column: 1 / -1;
    order: 5;
}

button.export-item:nth-child(4)::after {
    background: url(./images/quote.svg);
}

button.export-item:nth-child(4):hover::after {
    background: url(./images/quote_white.svg);
}

button.export-item:nth-child(5)::after {
    background: url(./images/link.svg);
}

button.export-item:nth-child(5):hover::after {
    background: url(./images/link_white.svg);
}

button.export-item:nth-child(1)::after {
    background: url(./images/dload.svg);
}

button.export-item:nth-child(1):hover::after {
    background: url(./images/dload_white.svg);
}

button.export-item:nth-child(3)::after {
    background: url(./images/cad.svg);
}

button.export-item:nth-child(3):hover::after {
    background: url(./images/cad_white.svg);
}

button.export-item:nth-child(2)::after {
    background: url(./images/dload.svg);
}

button.export-item:nth-child(2):hover::after {
    background: url(./images/dload_white.svg);
}

.property-choice.selected {
    font-weight: initial;
}

.container-lamella_wall .ppr-ROCKFON_LAM_Slats_Color, .container-lamella_wall .ppr-ROCKFON_LAM_SlatType {
    display: block !important;
}

.container-lamella_wall .property:nth-child(2) .property-choice::before {
    order: 1;
}


.container-lamella_wall .ppr-ROCKFON_LAM_SlatType .property-choice::after {
    content: "";
    background: red;
    width: 40px;
    height: 40px;
    margin: 0 8px 0 16px;
}

.container-lamella_wall .ppr-ROCKFON_LAM_SlatType .property-choice > div::before {
    content: "";
    font-size: 19px;
    line-height: 23px;
    letter-spacing: -0.02em;
    font-family: "Avenir LT W04_65 Medium";
    color: #0A0A0A;
    flex-grow: 1;
}

.container-lamella_wall .ppr-ROCKFON_LAM_SlatType .property-choice {
    display: flex;
}

.container-lamella_wall .ppr-ROCKFON_LAM_SlatType .property-choice::before {
    order: 1;
}

.container-lamella_wall .ppr-ROCKFON_LAM_SlatType .property-choice::after {
    order: 2;
}

.container-lamella_wall .ppr-ROCKFON_LAM_SlatType .property-choice div {
    order: 3;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #5C7289;
    font-size: 14px;
    line-height: 17px;
    font-family: "Avenir LT W04_65 Medium";
    letter-spacing: -0.02em;
}
.container-lamella_wall .ppr-ROCKFON_LAM_SlatType .property-choices {
    grid-template-columns: 1fr 1fr;
    grid-template-columns: repeat(1, 1fr);
}




.container-lamella_wall .ppr-ROCKFON_LAM_SlatType .property-choice:nth-child(2)::after {
    background: url(./images/narrow.svg);
    background-size: cover;
}

.container-lamella_wall .ppr-ROCKFON_LAM_SlatType .property-choice:nth-child(3)::after {
    background: url(./images/medium.svg);
    background-size: cover;
}


.container-lamella_wall .ppr-ROCKFON_LAM_SlatType .property-choice:nth-child(4)::after {
    background: url(./images/wide.svg);
    background-size: cover;
}


.container-lamella_wall .ppr-ROCKFON_LAM_GSlat .property-choice::after {
    content: "";
    background: red;
    width: 40px;
    height: 40px;
    margin: 0 8px 0 16px;
}

.container-lamella_wall .ppr-ROCKFON_LAM_GSlat .property-choice > div::before {
    content: "";
    font-size: 19px;
    line-height: 23px;
    letter-spacing: -0.02em;
    font-family: "Avenir LT W04_65 Medium";
    color: #0A0A0A;
    flex-grow: 1;
}

.container-lamella_wall .ppr-ROCKFON_LAM_GSlat .property-choice {
    display: flex;
}

.container-lamella_wall .ppr-ROCKFON_LAM_GSlat .property-choice::before {
    order: 1;
}

.container-lamella_wall .ppr-ROCKFON_LAM_GSlat .property-choice::after {
    order: 2;
}

.container-lamella_wall .ppr-ROCKFON_LAM_GSlat .property-choice div {
    order: 3;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #5C7289;
    font-size: 14px;
    line-height: 17px;
    font-family: "Avenir LT W04_65 Medium";
    letter-spacing: -0.02em;
}
.container-lamella_wall .ppr-ROCKFON_LAM_GSlat .property-choices {
    grid-template-columns: 1fr 1fr;
    grid-template-columns: repeat(1, 1fr);
}
.ppr-ROCKFON_LAM_RFHeight, .ppr-ROCKFON_LAM_RFWidth, .ppr-ROCKFON_LAM_Frame_Color, .ppr-ROCKFON_LAM_Slats_Length {display: none;}



.property.ppr-ROCKFON_LAM_SlatType::before {
    content: "";
    height: 166px;
    width: 100%;
    background: url(./images/expl_lam.svg);
    display: block;
    background-size: contain;
    margin-bottom: 32px;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #F7F9FA;
}

.property.ppr-ROCKFON_LAM_GSlat::before {
    content: "";
    height: 166px;
    width: 100%;
    background: url(./images/expl_lam_alt.svg);
    display: block;
    background-size: contain;
    margin-bottom: 32px;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #F7F9FA;
}


html:lang(pl) .property.ppr-ROCKFON_LAM_SlatType::before {
    background: url(./images/expl_lam_pl.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #F7F9FA;
}

html:lang(pl) .property.ppr-ROCKFON_LAM_GSlat::before {
    background: url(./images/expl_lam_alt_pl.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

html:lang(de) .property.ppr-ROCKFON_LAM_SlatType::before {
    background: url(./images/expl_lam_de.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #F7F9FA;
}

html:lang(de) .property.ppr-ROCKFON_LAM_GSlat::before {
    background: url(./images/expl_lam_alt_de.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

html:lang(cs) .property.ppr-ROCKFON_LAM_SlatType::before {
    background: url(./images/expl_lam_cs.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #F7F9FA;
}

html:lang(cs) .property.ppr-ROCKFON_LAM_GSlat::before {
    background: url(./images/expl_lam_alt_cs.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

html:lang(fr) .property.ppr-ROCKFON_LAM_SlatType::before {
    background: url(./images/expl_lam_fr.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #F7F9FA;
}

html:lang(fr) .property.ppr-ROCKFON_LAM_GSlat::before {
    background: url(./images/expl_lam_alt_fr.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

html:lang(es) .property.ppr-ROCKFON_LAM_SlatType::before {
    background: url(./images/expl_lam_es.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #F7F9FA;
}

html:lang(es) .property.ppr-ROCKFON_LAM_GSlat::before {
    background: url(./images/expl_lam_alt_es.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}









.container-lamella_wall .ppr-ROCKFON_LAM_GSlat .property-choice:nth-child(2)::after {
    background: url(./images/narrow.svg);
    background-size: cover;
}

.container-lamella_wall .ppr-ROCKFON_LAM_GSlat .property-choice:nth-child(3)::after {
    background: url(./images/medium.svg);
    background-size: cover;
}


.container-lamella_wall .ppr-ROCKFON_LAM_GSlat .property-choice:nth-child(4)::after {
    background: url(./images/wide.svg);
    background-size: cover;
}
























.container-lamella_wall .ROCKFON_LAM_Slats_Color .property-choice::after {
    content: "";
    background: red;
    width: 40px;
    height: 40px;
    margin: 0 8px 0 16px;
}

.container-lamella_wall .ROCKFON_LAM_Slats_Color .property-choice > div::before {
    content: "";
    color: #5C7289;
    font-size: 14px;
    line-height: 17px;
    font-family: "Avenir LT W04_65 Medium";
    letter-spacing: -0.02em;
}

.container-lamella_wall .ROCKFON_LAM_Slats_Color .property-choice {
    display: flex;
}

.container-lamella_wall .ROCKFON_LAM_Slats_Color .property-choice::before {
    order: 1;
}

.container-lamella_wall .ROCKFON_LAM_Slats_Color .property-choice::after {
    order: 2;
}

.container-lamella_wall .ROCKFON_LAM_Slats_Color .property-choice > div {
    order: 3;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
}
.container-lamella_wall .ROCKFON_LAM_Slats_Color {
    grid-template-columns: 1fr 1fr;
    grid-template-columns: repeat(1, 1fr);
}




.container-lamella_wall .ROCKFON_LAM_Slats_Color .property-choice:nth-child(2)::after {
    background: url(./images/light_oak.svg);
    background-size: cover;
}

.container-lamella_wall .ROCKFON_LAM_Slats_Color .property-choice:nth-child(3)::after {
    background: url(./images/oak.svg);
    background-size: cover;
}


.container-lamella_wall .ROCKFON_LAM_Slats_Color .property-choice:nth-child(4)::after {
    background: url(./images/smoked_oak.svg);
    background-size: cover;
}


/** Hub Styling **/

/* Dimension*/ 
.container-hub .ppr-ROCKFON_HUB_Panel_Size .property-choice::after {
    content: "";
    background: red;
    width: 40px;
    height: 40px;
    margin: 0 8px 0 16px;
}



.container-hub .ppr-ROCKFON_HUB_Panel_Size .property-choice {
    display: flex;
}

.container-hub .ppr-ROCKFON_HUB_Panel_Size .property-choice::before {
    order: 1;
}

.container-hub .ppr-ROCKFON_HUB_Panel_Size .property-choice::after {
    order: 2;
}



.container-hub .ppr-ROCKFON_HUB_Panel_Size .property-choice .property-choice-text-wrap::before {
    content: "";
    font-size: 19px;
    line-height: 23px;
    letter-spacing: -0.02em;
    font-family: "Avenir LT W04_65 Medium";
    color: #0A0A0A;
}
.container-hub .ppr-Tile.colour > div {
    color: #5C7289;
    font-size: 14px;
    line-height: 17px;
    font-family: "Avenir LT W04_65 Medium";
    letter-spacing: -0.02em;

}

.container-hub .ppr-ROCKFON_HUB_Panel_Size .property-choice div {
    order: 3;
    display: flex;
    flex-direction: column;
}



.container-hub .ppr-ROCKFON_HUB_Panel_Size .property-choice div {
    color: #5C7289;
    font-size: 14px;
    line-height: 17px;
}

.container-hub .ppr-ROCKFON_HUB_Panel_Size .property-choice:nth-child(2)::after {
    background: url(./images/hub_icon_small.svg);
}

.container-hub .ppr-ROCKFON_HUB_Panel_Size .property-choice:nth-child(3)::after {
    background: url(./images/hub_icon_medium.svg);
}

.container-hub .ppr-ROCKFON_HUB_Panel_Size .property-choice:nth-child(4)::after {
    background: url(./images/hub_icon_large.svg);
}

.container-hub .ppr-ROCKFON_HUB_Panel_Size .property-choice:nth-child(5)::after {
    background: url(./images/hub_icon_extra-large.svg);
}



.ROCKFON_HUB_Panel_Color .property-choice:nth-child(2) .property-choice-text-wrap {
    display: none;
}

.split-copy {
    content: "1000mm x 1300mm";
    color: #5C7289;
    font-size: 14px;
    line-height: 17px;
    font-family: "Avenir LT W04_65 Medium";
    letter-spacing: -0.02em;
}
.container-hub .property-class {
    display: grid;
}

.property.ppr-ROCKFON_HUB_Panel_Color {
    order: 2;
}

.property.ppr-ROCKFON_HUB_Felt {
    order: 3;
}

.ppr-ROCKFON_HUB_Felt_Color {
    order: 4;
}

.property.ppr-ROCKFON_HUB_Curtain {
    order: 5;
}

.property.ppr-ROCKFON_HUB_Curtain_Color {
    order: 6;
}

.container-hub .property.ppr-ROCKFON_HUB_Panel_Size {
    margin-top: 0;
}



/* Frame Colour*/ 
.container-hub .ROCKFON_HUB_Frame_Color {
	display: none;
}


.container-hub .property.ppr-ROCKFON_HUB_Panel_Size {
    order: 1;
}

.container-hub .property.ppr-Tile.colour {
    order: 2;
}

.container-hub .property.ppr-Felt {
    order: 3;
}

.container-hub .property.ppr-Felt.colour {
    order: 4;
}

.container-hub .property.ppr-Curtain {
    order: 5;
}

.container-hub .property.ppr-Curtain.colour {
    order: 6;
}

.property-choice .split-copy {
    width: 100%;
}

.property-choice .split-copy::first-letter {
    text-transform: uppercase;
}

h1#article-title {
    margin-bottom: 0;
	margin-top: 16px;
}

html .property-choice.selected:hover {
    pointer-events: unset !important;
    cursor: default;
}

.footer {
   display: none;
}
.property.ppr-ROCKFON_HUB_Panel_Color {
    display: block !important;
}

#export {
    margin-bottom: 16px;
}

.ROCKFON_SCR_Screen_Color .property-choice::after {
    content: "";
    width: 32px;
    height: 32px;
    border-radius: 40px;
    order: 2;
    margin-left: 16px;
    margin-right: 8px;
    border: 4px solid white;
}

.ROCKFON_SCR_Screen_Color .property-choice {
    display: flex;
}

.ROCKFON_SCR_Screen_Color .property-choice::before {
    order: 1;
}

.ROCKFON_SCR_Screen_Color .property-choice > div {
    order: 3;
}

.ROCKFON_SCR_Screen_Color .property-choice:nth-child(2)::after {
    background: #DFE5D7;
}

.ROCKFON_SCR_Screen_Color .property-choice:nth-child(3)::after {
    background: #96AD99;
}

.ROCKFON_SCR_Screen_Color .property-choice:nth-child(4)::after {
    background: #295D51;
}

.ROCKFON_SCR_Screen_Color .property-choice:nth-child(5)::after {
    background: #29362C;
}

.ROCKFON_SCR_Screen_Color .property-choice:nth-child(6)::after {
    background: #E3D3BA;
}
.ROCKFON_SCR_Screen_Color .property-choice:nth-child(7)::after {
    background: #DAB686;
}
.ROCKFON_SCR_Screen_Color .property-choice:nth-child(8)::after {
    background: #DDA84A;
}
.ROCKFON_SCR_Screen_Color .property-choice:nth-child(9)::after {
    background: #F2DDCA;
}
.ROCKFON_SCR_Screen_Color .property-choice:nth-child(10)::after {
    background: #E7C7B0;
}
.ROCKFON_SCR_Screen_Color .property-choice:nth-child(11)::after {
    background: #BA665C;
}
.ROCKFON_SCR_Screen_Color .property-choice:nth-child(12)::after {
    background: #69291F;
}
.ROCKFON_SCR_Screen_Color .property-choice:nth-child(13)::after {
    background: #A9BBBB;
}
.ROCKFON_SCR_Screen_Color .property-choice:nth-child(14)::after {
    background: #7C99A7;
}
.ROCKFON_SCR_Screen_Color .property-choice:nth-child(15)::after {
    background: #314F67;
}

.ROCKFON_SCR_Screen_Color .property-choice:nth-child(16)::after {
    background: #012F46;
}

.ROCKFON_SCR_Screen_Color .property-choice:nth-child(17)::after {
    background: #D1BFA9;
}

.ROCKFON_SCR_Screen_Color .property-choice:nth-child(18)::after {
    background: #9A8C7F;
}

.ROCKFON_SCR_Screen_Color .property-choice:nth-child(19)::after {
    background: #828071;
}

.ROCKFON_SCR_Screen_Color .property-choice:nth-child(20)::after {
    background: #414548;
}

.ROCKFON_SCR_Screen_Color .property-choice:nth-child(21)::after {
    background: #B4A794;
}

.ROCKFON_SCR_Screen_Color .property-choice:nth-child(22)::after {
    background: #A58F7A;
}

.ROCKFON_SCR_Screen_Color .property-choice:nth-child(23)::after {
    background: #8A7564;
}

.ROCKFON_SCR_Screen_Color .property-choice:nth-child(24)::after {
    background: #281008;
}

.ROCKFON_SCR_Screen_Color .property-choice:nth-child(25)::after {
    background: #DDE1E4;
}

.ROCKFON_SCR_Screen_Color .property-choice:nth-child(26)::after {
    background: #CAC7C2;
}

.ROCKFON_SCR_Screen_Color .property-choice:nth-child(27)::after {
    background: #9AA09E;
}

.ROCKFON_SCR_Screen_Color .property-choice:nth-child(28)::after {
    background: #3A4751;
}

.ROCKFON_SCR_Screen_Color .property-choice:nth-child(29)::after {
    background: #BDC2C5;
}

.ROCKFON_SCR_Screen_Color .property-choice:nth-child(30)::after {
    background: #B1B7B7;
}
.ROCKFON_SCR_Screen_Color .property-choice:nth-child(31)::after {
    background: #6E6E6E;
}
.ROCKFON_SCR_Screen_Color .property-choice:nth-child(32)::after {
    background: #1F1F1D;
}
.ROCKFON_SCR_Screen_Color .property-choice:nth-child(33)::after {
    background: #D7B7A2;
}
.ROCKFON_SCR_Screen_Color .property-choice:nth-child(34)::after {
    background: #929095;
}
.ROCKFON_SCR_Screen_Color .property-choice:nth-child(35)::after {
    background: #979795;
}

.ROCKFON_SCR_Width .property-choice::after {
    content: "";
    width: 40px;
    height: 40px;
    border-radius: 0;
    order: 2;
    margin-left: 16px;
    margin-right: 8px;
}

.ROCKFON_SCR_Width .property-choice {
    display: flex;
}

.ROCKFON_SCR_Width .property-choice::before {
    order: 1;
}

.ROCKFON_SCR_Width .property-choice > div {
    order: 3;
}

.disa .property-choice {
    pointer-events: none;
}

.ROCKFON_SCR_Height, .ROCKFON_SCR_Depth {
    display: none !important;
}

.ROCKFON_SCR_Width .property-choice:nth-child(2)::after {
    background: url(./images/floor_small.svg);
}

.ROCKFON_SCR_Width .property-choice:nth-child(3)::after {
    background: url(./images/floor_large.svg);
}

.ROCKFON_SCR_Width .property-choice:nth-child(4)::after {
    background: url(./images/floor_medium.svg);
}



.ROCKFON_SCR_Width .property-choice > div::after {
    content: "1000mm x 1300mm";
    color: #5C7289;
    font-size: 14px;
    line-height: 17px;
    font-family: "Avenir LT W04_65 Medium";
    letter-spacing: -0.02em;
}

.ROCKFON_SCR_Width .property-choice > div div {
    color: #5C7289;
    font-size: 14px;
    line-height: 17px;
    font-family: "Avenir LT W04_65 Medium";
    letter-spacing: -0.02em;
}



.ROCKFON_SCR_Width .property-choice:nth-child(3) > div::after {
    content: "1200mm x 1800mm";
}

.ROCKFON_SCR_Width .property-choice:nth-child(4) > div::after {
    content: "1700mm x 1300mm";
}
.ROCKFON_SCR_Width .property-choice:nth-child(4) {
    order: 2;
}

.ROCKFON_SCR_Width .property-choice:nth-child(3) {
    order: 3;
}

.ROCKFON_SCR_Width .property-choice:nth-child(2) {
    order: 1;
}
.ROCKFON_SCR_Width .property-choice > div div {
    display: none;
}


.header-main {
    border-bottom: 1px solid rgb(171 184 199 / 50%);
}

.container-scr .property-class:nth-child(1) {
    order: 2;
}

.container-scr .property-class:nth-child(2) {
    order: 3;
}


.container-scr .property-class:nth-child(3) {
    order: 1;
}

.container-scr #property-editor-content {
    display: grid;
}
.ROCKFON_SCR_Height, .ROCKFON_SCR_Depth, .ROCKFON_SCR_DIM_Typ { margin-top: 0px !important;
}

/* .property.ppr-ROCKFON_SCR_DIM_Typ {
    display: none;
    margin: 0;
} */

.property.ppr-ROCKFON_SCR_Height {
    margin: 0;
}

.property.ppr-ROCKFON_SCR_Depth {
    margin: 0;
}



.ROCKFON_SCR_Frame_Color .property-choice > div div {
    display: none;
}

.ROCKFON_SCR_Frame_Color .property-choice::after {
    content: "";
    width: 32px;
    height: 32px;
    border-radius: 40px;
    order: 2;
    margin-left: 16px;
    margin-right: 8px;
    border: 4px solid white !important;
}

.ROCKFON_SCR_Frame_Color .property-choice {
    display: flex;
}

.ROCKFON_SCR_Frame_Color .property-choice::before {
    order: 1;
}

.ROCKFON_SCR_Frame_Color .property-choice > div {
    order: 3;
}

.ROCKFON_SCR_Frame_Color .property-choice:nth-child(3)::after {
    background: #000;
}

.ROCKFON_SCR_Frame_Color .property-choice:nth-child(2)::after {
    background: #fff;
    outline: 1px solid #ABB8C7;
    width: 24px;
    height: 24px;
    margin-left: 20px;
    margin-right: 12px;
}

.ROCKFON_SCR_Height_i .property-choice-text-wrap, .ROCKFON_SCR_Width_i .property-choice-text-wrap {
    color: #5C7289;
}

.ROCKFON_SCR_Foot .property-choice::after {
content: "";
    width: 1px;
    height: 40px;
    border-radius: 40px;
    order: 2;
}

.ROCKFON_SCR_Foot .property-choice::before {
    margin-right: 16px;
}


.ROCKFON_SCR_Screen_Color .property-choice:nth-child(2) {
    order: 2;
}

.ROCKFON_SCR_Screen_Color .property-choice:nth-child(3) {
    order: 3;
}

.ROCKFON_SCR_Screen_Color .property-choice:nth-child(4) {
    order: 4;
}

.ROCKFON_SCR_Screen_Color .property-choice:nth-child(5) {
    order: 5;
}

.ROCKFON_SCR_Screen_Color .property-choice:nth-child(6) {
    order: 10;
}
.ROCKFON_SCR_Screen_Color .property-choice:nth-child(7) {
    order: 11;
}
.ROCKFON_SCR_Screen_Color .property-choice:nth-child(8) {
    order: 12;
}
.ROCKFON_SCR_Screen_Color .property-choice:nth-child(9) {
    order: 14;
}
.ROCKFON_SCR_Screen_Color .property-choice:nth-child(10) {
    order: 15;
}
.ROCKFON_SCR_Screen_Color .property-choice:nth-child(11) {
    order: 16;
}
.ROCKFON_SCR_Screen_Color .property-choice:nth-child(12) {
    order: 17;
}
.ROCKFON_SCR_Screen_Color .property-choice:nth-child(13) {
    order: 6;
}
.ROCKFON_SCR_Screen_Color .property-choice:nth-child(14) {
    order: 7;
}
.ROCKFON_SCR_Screen_Color .property-choice:nth-child(15) {
    order: 8;
}

.ROCKFON_SCR_Screen_Color .property-choice:nth-child(16) {
    order: 9;
}

.ROCKFON_SCR_Screen_Color .property-choice:nth-child(17) {
    order: 18;
}

.ROCKFON_SCR_Screen_Color .property-choice:nth-child(18) {
    order: 20;
}

.ROCKFON_SCR_Screen_Color .property-choice:nth-child(19) {
    order: 21;
}

.ROCKFON_SCR_Screen_Color .property-choice:nth-child(20) {
    order: 33;
}

.ROCKFON_SCR_Screen_Color .property-choice:nth-child(21) {
    order: 19;
}

.ROCKFON_SCR_Screen_Color .property-choice:nth-child(22) {
    order: 22;
}

.ROCKFON_SCR_Screen_Color .property-choice:nth-child(23) {
    order: 23;
}

.ROCKFON_SCR_Screen_Color .property-choice:nth-child(24) {
    order: 34;
}

.ROCKFON_SCR_Screen_Color .property-choice:nth-child(25) {
    order: 24;
}

.ROCKFON_SCR_Screen_Color .property-choice:nth-child(26) {
    order: 31;
}

.ROCKFON_SCR_Screen_Color .property-choice:nth-child(27) {
    order: 28;
}

.ROCKFON_SCR_Screen_Color .property-choice:nth-child(28) {
    order: 32;
}

.ROCKFON_SCR_Screen_Color .property-choice:nth-child(29) {
    order: 25;
}

.ROCKFON_SCR_Screen_Color .property-choice:nth-child(30) {
    order: 26;
}
.ROCKFON_SCR_Screen_Color .property-choice:nth-child(31) {
    order: 27;
}
.ROCKFON_SCR_Screen_Color .property-choice:nth-child(32) {
    order: 35;
}
.ROCKFON_SCR_Screen_Color .property-choice:nth-child(33) {
    order: 13;
}
.ROCKFON_SCR_Screen_Color .property-choice:nth-child(34) {
    order: 30;
}
.ROCKFON_SCR_Screen_Color .property-choice:nth-child(35) {
    order: 29;
}

.ROCKFON_SCR_Frame_Color .property-choice:nth-child(2) {
    order: 2;
}

.ROCKFON_SCR_Frame_Color .property-choice:nth-child(3) {
    order: 1;
}

.ROCKFON_SCR_Width .property-title {display: none;}




.ROCKFON_SCR_Screen_Color {
    height: 538px;
    transition: .3s ease;
    white-space: nowrap;
    overflow-x: visible;
    overflow-y: hidden;
}

.ROCKFON_SCR_Screen_Color.showme {
    height: 1762px;
    transition: .3s ease;
}

.ROCKFON_SCR_Screen_Color {
    padding-left: 4px;
    padding-right: 4px;
    width: calc(100% + 8px);
    position: relative;
    left: -4px;
}



button.but3 {
    margin-top: 12px;
    width: 100%;
    height: 90px;
    border: 1px solid #CAD2DB;
    background: transparent;
    display: flex;
    align-items: center;
    padding: 23px;
}

button.but3:hover {
    border: 1px solid #002C3F;
    cursor: pointer;
}

button.but3::after {
    content: "";
    display: block;
    height: 24px;
    width: 24px;
    background: url(./images/cad.svg);
	transform: rotate(90deg);
    transition: .3s ease;
}

button.but3.showme::after {
    transform: rotate(270deg);
}

button.but3 {
	background: url(./images/c_extended.svg);
    background-repeat: no-repeat;
    background-position: 23px calc(50% + 14px);
}

button.but3.showme {
	background: url(./images/c_compact.svg);
}

button.but3::before {
    margin-bottom: 18px;
}

button.but3.showme {
    background-repeat: no-repeat;
    background-position: 23px calc(50% + 14px);
}

.ROCKFON_SCR_Screen_Color .split-copy {
    display: none;
}


.container-hdi .ROCKFON_SCR_Width .property-choice:nth-child(4) {
    order: 3;
}

.container-hdi .ROCKFON_SCR_Width .property-choice:nth-child(3) {
    order: 2;
}

.container-hdi .ROCKFON_SCR_Width .property-choice:nth-child(2) {
    order: 1;
}

.container-hdi .ROCKFON_SCR_Width .property-choice > div::after {
    content: "600mm x 1800mm";
}



.container-hdi .ROCKFON_SCR_Width .property-choice:nth-child(3) > div::after {
    content: "900mm x 1800mm";
}


.container-hdi .ROCKFON_SCR_Width .property-choice:nth-child(4) > div::after {
    content: "1200mm x 1800mm";
}


.container-hdi .ROCKFON_SCR_Width .property-choice:nth-child(2)::after {
    background: url(./images/divider_small.svg);
}

.container-hdi .ROCKFON_SCR_Width .property-choice:nth-child(3)::after {
    background: url(./images/divider_medium.svg);
}

.container-hdi .ROCKFON_SCR_Width .property-choice:nth-child(4)::after {
    background: url(./images/divider_large.svg);
}

.container-hdi .property-class:nth-child(3) {
    order: 1;
}

.container-hdi .property-class:nth-child(2) {
    order: 3;
}

.container-hdi .property-class:nth-child(1) {
    order: 3;
}

.container-hdi #property-editor-content {
    display: grid;
}

.property.ppr-ROCKFON_SCR_Ceiling_distance, .property.ppr-ROCKFON_SCR_Bottom_distance, .property.ppr-ROCKFON_SCR_Space_height {
    display: none;
    margin: 0;
}


.container-pan .ROCKFON_SCR_Width .property-choice:nth-child(7) {
    order: 6;
}
.container-pan .ROCKFON_SCR_Width .property-choice:nth-child(6) {
    order: 5;
}
.container-pan .ROCKFON_SCR_Width .property-choice:nth-child(5) {
    order: 4;
}
.container-pan .ROCKFON_SCR_Width .property-choice:nth-child(4) {
    order: 3;
}

.container-pan .ROCKFON_SCR_Width .property-choice:nth-child(3) {
    order: 2;
}

.container-pan .ROCKFON_SCR_Width .property-choice:nth-child(2) {
    order: 1;
}

.container-pan .ROCKFON_SCR_Width .property-choice > div::after {
    content: "600mm x 900mm";
}


.container-pan .ROCKFON_SCR_Width .property-choice:nth-child(3) > div::after {
    content: "900mm x 1800mm";
}


.container-pan .ROCKFON_SCR_Width .property-choice:nth-child(4) > div::after {
    content: "1200mm x 900mm";
}


.container-pan .ROCKFON_SCR_Width .property-choice:nth-child(5) > div::after {
    content: "1800mm x 1200mm";
}


.container-pan .ROCKFON_SCR_Width .property-choice:nth-child(6) > div::after {
    content: "2400mm x 1200mm";
}


.container-pan .ROCKFON_SCR_Width .property-choice:nth-child(7) > div::after {
    content: "3000mm x 1200mm";
}


.container-pan .ROCKFON_SCR_Width .property-choice:nth-child(2)::after {
    background: url(./images/h_1.svg);
}

.container-pan .ROCKFON_SCR_Width .property-choice:nth-child(3)::after {
    background: url(./images/h_2.svg);
}

.container-pan .ROCKFON_SCR_Width .property-choice:nth-child(4)::after {
    background: url(./images/h_3.svg);
}

.container-pan .ROCKFON_SCR_Width .property-choice:nth-child(5)::after {
    background: url(./images/h_4.svg);
}

.container-pan .ROCKFON_SCR_Width .property-choice:nth-child(6)::after {
    background: url(./images/h_5.svg);
}

.container-pan .ROCKFON_SCR_Width .property-choice:nth-child(7)::after {
    background: url(./images/h_6.svg);
}

.container-pan .property-class:nth-child(3) {
    order: 1;
}

.container-pan .property-class:nth-child(2) {
    order: 3;
}

.container-pan .property-class:nth-child(1) {
    order: 3;
}

.container-pan #property-editor-content {
    display: grid;
}

.property.ppr-ROCKFON_SCR_Ceiling_distance, .property.ppr-ROCKFON_SCR_Bottom_distance, .property.ppr-ROCKFON_SCR_Space_height {
    display: none;
    margin: 0;
}

div#request-a-quote {
    top: 0;
    right: 0;
    background: #fff;
    bottom: 0;
    width: 100% !important;
    padding: 32px;
}

.ui-dialog.ui-corner-all.ui-widget.ui-widget-content.ui-front.ui-draggable.ui-resizable {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    left: unset !important;
    width: 40% !important;
    display: block !important;
    bottom: 0 !important;
    background: #fff;
    transform: translateX(100%);
    transition: .3s ease;
}


.ui-dialog.ui-corner-all.ui-widget.ui-widget-content.ui-front.ui-draggable.ui-resizable.showmark {
    transform: translateX(0);
}

.mktoForm {
    width: 100% !important;
}

.ui-dialog-titlebar.ui-corner-all.ui-widget-header.ui-helper-clearfix.ui-draggable-handle {
    position: fixed;
    top: 32px;
    right: calc(100% - 232px);
    left: unset;
    background: transparent;
    z-index: 99;
    width: 200px;
}

.ui-dialog-titlebar.ui-corner-all.ui-widget-header.ui-helper-clearfix.ui-draggable-handle span#ui-id-1 {
    display: none;
}

.ui-dialog.ui-corner-all.ui-widget.ui-widget-content.ui-front.ui-draggable.ui-resizable.showmark {
    transform: translateX(0);
}

.property-choice {
    transition: .15s ease;
}

.ROCKFON_SCR_Screen_Color .property-choice::before {
    transition: .15s ease;
}

.ROCKFON_SCR_Frame_Color .property-choice::before {
    transition: .15s ease;
}

button.but3 {
    transition: .15s ease;
}

.ROCKFON_SCR_Width .property-choice::before {
    transition: .15s ease;
}

button.export-item::after {
    transition: .15s ease;
}

button.ui-button.ui-corner-all.ui-widget.ui-button-icon-only.ui-dialog-titlebar-close::before {
    content: "";
	background: url(./images/quote.svg);
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
    height: 24px;
    width: 24px;
    transform: rotate(180deg);
    margin-right: 12px;
}


button.ui-button.ui-corner-all.ui-widget.ui-button-icon-only.ui-dialog-titlebar-close {
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #000000;
    font-family: "Avenir LT W04_65 Medium";
    background: transparent;
    border: 0;
    padding-left: 0 !important;
    transition: .15s ease;
}

/* button.ui-button.ui-corner-all.ui-widget.ui-button-icon-only.ui-dialog-titlebar-close::after {
    content: "slide-out";
    padding-left: 6px;
} */

button.ui-button.ui-corner-all.ui-widget.ui-button-icon-only.ui-dialog-titlebar-close:hover {
    cursor: pointer;
}

button.ui-button.ui-corner-all.ui-widget.ui-button-icon-only.ui-dialog-titlebar-close:hover {
    opacity: .6;
}

div#request-a-quote h1 {
    margin-top: 64px;
    font-size: 28px;
    line-height: 34px;
/* identical to box height, or 121% */
    letter-spacing: -0.02em;
    color: #000000;
    font-family: "Avenir LT W04_65 Medium";
    font-weight: 500;
	margin-bottom: 18px;
}

div#request-a-quote p {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
/* or 144% */
    color: #000000;
    font-family: "Avenir LT W04_45 Book";
}

.mktoFormRow > div {
    width: 100%;
    display: flex;
    flex-direction: column;
}

html .mktoForm .mktoRequiredField label.mktoLabel {
    width: 100% !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 16px;
    line-height: 24px;
    font-family: "Avenir LT W04_45 Book" !important;
    color: #000;
    display: flex;
    flex-direction: row;
    padding-bottom: 6px;
    padding-top: 0;
}

.mktoForm input[type=text], .mktoForm input[type=url], .mktoForm input[type=email], .mktoForm input[type=tel], .mktoForm input[type=number], .mktoForm input[type=date], .mktoForm textarea.mktoField, .mktoForm select.mktoField {
    width: 100% !important;
    height: 80px;

/* Neutral/Gray 300 */
    border: 1px solid #CAD2DB;
}

.cf_widget {
    display: none !important;
}

.ui-dialog.ui-corner-all.ui-widget.ui-widget-content.ui-front.ui-draggable.ui-resizable.showmark {
    overflow-y: auto;
}

html .mktoForm .mktoRequiredField label.mktoLabel strong {
    font-weight: 400;
}

.mktoForm .mktoRequiredField .mktoAsterix {
    order: 2;
    color: #5C7289;
}

.mktoForm .mktoGutter {
    display: none;
}

.mktoForm .mktoError {
    display: none !important;
}


#miscRFNField6 {
    display: none !important;
}

label#LblmiscRFNField6 {
    display: none;
}

div#property-editor {
    height: 100vh;
    overflow-y: scroll;
}


.container-P286920 .ROCKFON_SEN_Time, .container-P286920 .ROCKFON_SEN_GMountOK, .container-P286920 .ROCKFON_SEN_GLength, .container-P286920 .ROCKFON_SEN_GWidth, .container-P286920 .ROCKFON_SEN_Shape {
    display: none;
}

.container-P286920 .ppr-ROCKFON_SEN_GSHAPEMT .property-choice::after {
    content: "";
    background: red;
    width: 40px;
    height: 40px;
    margin: 0 8px 0 16px;
}

.container-P286920 .ppr-ROCKFON_SEN_GSHAPEMT .property-choice:nth-child(2)::after {
    background: url(./images/rec.svg);;
}

.container-P286920 .ppr-ROCKFON_SEN_GSHAPEMT .property-choice:nth-child(3)::after {
    background: url(./images/sq.svg);;
}

.container-P286920 .ppr-ROCKFON_SEN_GSHAPEMT .property-choice div {
    font-size: 19px;
    line-height: 23px;
    letter-spacing: -0.02em;
    font-family: "Avenir LT W04_65 Medium";
    color: #0A0A0A;
}

.container-P286920 .ppr-ROCKFON_SEN_GSHAPEMT .property-choice {
    display: flex;
}


.container-P286920 .ppr-ROCKFON_SEN_GSHAPEMT .property-choice:nth-child(2) .property-choice-text-wrap::after {
    content: "1760mm x 1160mm";
}

.container-P286920 .ppr-ROCKFON_SEN_GSHAPEMT .property-choice:nth-child(3) .property-choice-text-wrap::after {
    content: "1160mm x 1160mm";
}

.container-P286920 .ppr-ROCKFON_SEN_GSHAPEMT .property-choice::after {
    order: 2;
}

.container-P286920 .ppr-ROCKFON_SEN_GSHAPEMT .property-choice::before {
    order: 1;
}

.container-P286920 .ppr-ROCKFON_SEN_GSHAPEMT .property-choice div  {
    order: 3;
}

/* SEN_ GAligment */

.container-P286920 .ppr-ROCKFON_SEN_GAlignment .property-choice::after {
    content: "";
    background: red;
    width: 40px;
    height: 40px;
    margin: 0 8px 0 16px;
}

.container-P286920 .ppr-ROCKFON_SEN_GAlignment .property-choice:nth-child(2)::after {
    background: url(./images/rec.svg);
}

.container-P286920 .ppr-ROCKFON_SEN_GAlignment .property-choice:nth-child(3)::after {
    background: url(./images/rec.svg);
    transform: rotate(90deg);
}

.container-P286920 .ppr-ROCKFON_SEN_GAlignment .property-choice div {
    font-size: 19px;
    line-height: 23px;
    letter-spacing: -0.02em;
    font-family: "Avenir LT W04_65 Medium";
    color: #0A0A0A;
    text-transform: capitalize;
}

.container-P286920 .ppr-ROCKFON_SEN_GAlignment .property-choice {
    display: flex;
}


.container-P286920 .ppr-ROCKFON_SEN_GAlignment .property-choice:nth-child(2) .property-choice-text-wrap::after {
    content: "1760mm x 1160mm";
}

.container-P286920 .ppr-ROCKFON_SEN_GAlignment .property-choice:nth-child(3) .property-choice-text-wrap::after {
    content: "1160mm x 1760mm";
}

.container-P286920 .ppr-ROCKFON_SEN_GAlignment .property-choice::after {
    order: 2;
}

.container-P286920 .ppr-ROCKFON_SEN_GAlignment .property-choice::before {
    order: 1;
}

.container-P286920 .ppr-ROCKFON_SEN_GAlignment .property-choice div  {
    order: 3;
}




/* SEN_Colo */



/* SEN_GFASTENINGTyp */



.container-P286920 .ppr-ROCKFON_SEN_GFASTENINGTyp .property-choice::after {
    content: "";
    background: red;
    width: 40px;
    height: 40px;
    margin: 0 8px 0 16px;
}

.container-P286920 .ppr-ROCKFON_SEN_GFASTENINGTyp .property-choice:nth-child(2)::after {
    background: url(./images/spcsm.svg);
}

.container-P286920 .ppr-ROCKFON_SEN_GFASTENINGTyp .property-choice:nth-child(3)::after {
    background: url(./images/spcl.svg);
}

.container-P286920 .ppr-ROCKFON_SEN_GFASTENINGTyp .property-choice div {
    font-size: 19px;
    line-height: 23px;
    letter-spacing: -0.02em;
    font-family: "Avenir LT W04_65 Medium";
    color: #0A0A0A;
}

.container-P286920 .ppr-ROCKFON_SEN_GFASTENINGTyp .property-choice {
    display: flex;
}

.container-P286920 .ppr-ROCKFON_SEN_GFASTENINGTyp .property-choice::after {
    order: 2;
}

.container-P286920 .ppr-ROCKFON_SEN_GFASTENINGTyp .property-choice::before {
    order: 1;
}

.container-P286920 .ppr-ROCKFON_SEN_GFASTENINGTyp .property-choice div  {
    order: 3;
}


.container-P286920 #article-title {
    white-space: nowrap;
    overflow: hidden;
    max-width: 280px;
}


[aria-describedby="downloads"] div#downloads {
    padding: 32px;
}


[aria-describedby="downloads"] div#downloads p:nth-child(1) {
    display: none;
}

[aria-describedby="downloads"] div#downloads p:nth-child(2) {margin-top: 64px;font-size: 28px;line-height: 34px;letter-spacing: -0.02em;color: #000000;font-family: "Avenir LT W04_65 Medium";font-weight: 500;margin-bottom: 18px;}

[aria-describedby="downloads"] div#downloads p:nth-child(3) {
    margin-bottom: 32px;
	font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: #000000;
    font-family: "Avenir LT W04_45 Book";
}

[aria-describedby="downloads"] div#downloads button.export-item {
 margin-bottom: 16px;
}

[aria-describedby="downloads"] div#downloads {
    padding: 32px;
}


[aria-describedby="downloads"] div#downloads p:nth-child(1) {
    display: none;
}

[aria-describedby="downloads"] div#downloads p:nth-child(2) {margin-top: 64px;font-size: 28px;line-height: 34px;letter-spacing: -0.02em;color: #000000;font-family: "Avenir LT W04_65 Medium";font-weight: 500;margin-bottom: 18px;}

[aria-describedby="downloads"] div#downloads p:nth-child(3) {
    margin-bottom: 32px;
}

[aria-describedby="downloads"] div#downloads button.export-item {
 margin-bottom: 16px;
}
/******************/


[aria-describedby="persistence-share"] div#persistence-share {
    padding: 32px;
}

[aria-describedby="persistence-share"] div#persistence-share p:nth-child(1) {
    display: none;
}

[aria-describedby="persistence-share"] div#persistence-share p:nth-child(2) {margin-top: 64px;font-size: 28px;line-height: 34px;letter-spacing: -0.02em;color: #000000;font-family: "Avenir LT W04_65 Medium";font-weight: 500;margin-bottom: 18px;}

[aria-describedby="persistence-share"] div#persistence-share p:nth-child(3) {
    margin-bottom: 32px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: #000000;
    font-family: "Avenir LT W04_45 Book";
}

div#persistence-share {}

div#persistence-share {
    display: block;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
/* identical to box height, or 150% */
    align-items: center;

/* Neutral/Gray 600 */
    color: #5C7289;
    font-family: "Avenir LT W04_65 Medium";
}

input#persistence-share-link-input {
    display: block;
    width: calc( 100% - 146px);
    margin-top: 6px;
    margin-right: 146px;
    padding: 24px;
    color: #5C7289;
    font-weight: 500;
    font-family: "Avenir LT W04_65 Medium";
    font-size: 19px;
    line-height: 23px;
    height: 80px;
    border: 1px solid #CAD2DB;
    transition: .15s ease;
}

input#persistence-share-link-input > div {
font-weight: 500;
display: flex;
align-items: center;
letter-spacing: -0.02em;
color: #5C7289;

}

input#persistence-share-link-input:hover {
    border: 1px solid #001620;
    color: #001620;
}
input#persistence-share-link-input:focus-visible {
 outline: 0;
}

/* 
input#persistence-share-link-input:focus-visible {
    border: 2px solid #0092D1;
    padding-left: 23px;
    box-shadow: 0px 0px 0px 4px rgb(155 225 255);
    outline: 0;
}
*/





button#peristence-share-copy-button {
    height: 80px;
    padding: 0 40px;
    font-size: 19px;
    line-height: 23px;
    color: #fff;
    background: #001620;
    border: 1px solid #fff;
    float: right;
    position: relative;
    top: -80px;
    width: 130px;
    transition: .15s ease;
}

button#peristence-share-copy-button:hover {
 cursor: pointer;
 background: #000;
}

a#persistence-share-email, a#persistence-share-link-anchor {
    color: #00638E;
    text-decoration: none;
    padding-right: 8px;
}


div#misc-buttons {
    border: 1px solid #cad2db;
    padding: 7px 32px;
    border-radius: 100px;
    top: unset !important;
    bottom: 64px !important;
    left: calc(60% /2 - 139px ) !important;
    display: flex;
    gap: 32px;
}

div#misc-buttons button {
    background: transparent;
    border: 0;
    padding: 0px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    align-items: center;
    color: #5C7289;
    font-family: "Avenir LT W04_65 Medium";
    transition: .15s ease;
}

div#misc-buttons button:hover {
    cursor: pointer;
    color: #001620;
}

div#misc-buttons {
    background-color: #f7f9fa;
}


.ROCKFON_SCR_Width_i .property-choice::before {
    display: none;
}

.ROCKFON_SCR_Width_i .property-choice {
    width: 100%;
    border: 1px solid #c9d2db;
    box-shadow: none;
    display: flex;
    justify-content: space-between;
	padding: 24px;
}

.property .property-choices.ROCKFON_SCR_Width_i {
    grid-template-columns: 1fr 1fr;
    grid-template-columns: repeat(1, 1fr);
}

.ROCKFON_SCR_Width_i .property-choice:hover {
    border: 1px solid #002C3F;
    cursor: pointer !important;
}

.ROCKFON_SCR_Width_i .property-header {
    display: none;
}

/* Height */

.ROCKFON_SCR_Height_i .property-choice::before {
    display: none;
}

.ROCKFON_SCR_Height_i .property-choice {
    width: 100%;
    border: 1px solid #c9d2db;
    box-shadow: none;
    display: flex;
    justify-content: space-between;
	padding: 24px;
}

.property .property-choices.ROCKFON_SCR_Height_i {
    grid-template-columns: 1fr 1fr;
    grid-template-columns: repeat(1, 1fr);
}

.ROCKFON_SCR_Height_i .property-choice:hover {
    border: 1px solid #002C3F;
    cursor: pointer !important;
}

.ROCKFON_SCR_Height_i .property-header {
    display: none;
}

.ROCKFON_SCR_Width_i .property-choice::after {
    content: "";
    height: 40px;
    width: 40px;
    background: url(./images/icon_width.svg);
	background-position: center;
}

.ROCKFON_SCR_Height_i .property-choice::after {
    content: "";
    height: 40px;
    width: 40px;
    background: url(./images/icon_height.svg);
	background-position: center;
}

.ROCKFON_SCR_DIM_Typ .property-choice::after {
    content: "";
    width: 40px;
    height: 40px;
    border-radius: 0;
    order: 2;
    margin-left: 16px;
    margin-right: 8px;
}

.ROCKFON_SCR_DIM_Typ .property-choice:nth-child(2)::after {
    background: url(./images/default.svg);
	background-position: center;
}

.ROCKFON_SCR_DIM_Typ .property-choice:nth-child(3)::after {
    background: url(./images/custom.svg);
	background-position: center;
}


.ROCKFON_SCR_DIM_Typ .property-choice::before {
    order: 1;
}

.ROCKFON_SCR_DIM_Typ .property-choice div {
    order: 3;
}

.ROCKFON_SCR_Height_i .property-choice-text-wrap::after, .ROCKFON_SCR_Width_i .property-choice-text-wrap::after {
    content: "mm";
    left: -4px;
    position: relative;
    background: white;
}


.custom-size-input {
    width: 100%;
    background: #fff;
    flex-direction: row;
    margin-top: -102px;
    z-index: 9;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 12px;
    grid-row-gap: 12px;
}

.custom-size-input button {
    border: 1px solid #0A0A0A;
    padding: 24px;
    background: transparent;
    text-align: left;
    font-size: 18px;
    line-height: 40px;
    font-family: "Avenir LT W04_65 Medium";
    color: #0A0A0A;
    justify-content: space-between;
    transition: .15s ease;
    align-items: center;
    width: 100%;
}

.custom-size-input input {
    font-family: "Avenir LT W04_65 Medium";
    color: #0A0A0A;
    font-weight: 500;
    font-size: 19px;
    line-height: 23px;
    letter-spacing: -0.02em;
    color: #5C7289;
    padding: 24px;
    border: 1px solid #c8d2db;
    grid-area: 1 / 1 / 2 / 3;
    display: flex;
}

.custom-size-input input:focus-within {
    border: 2px solid #0092D1;
    padding: 23px;
    box-shadow: 0px 0px 0px 4px rgb(155 225 255);
    outline: 0;
}

.custom-size-input button:hover {
    background: #000;
    color: #fff;
    cursor: pointer;
}



.custom-size-input input:hover {
    border: 1px solid #002C3F;
}

.custom-size-input button:nth-child(2) {
    grid-area: 2 / 2 / 3 / 3;
}

.custom-size-input button:nth-child(3) {
    grid-area: 2 / 1 / 3 / 2;
}



.custom-size-input input:focus-within:hover {
    border: 2px solid #0092D1;
}

.ROCKFON_SCR_Height .property-choice, .ROCKFON_SCR_Width_i .property-choice {
    padding: 24px;
    display: flex;
    align-items: center;
}


.custom-size-input button:nth-child(2)::after {
    content: "";
    display: block;
    height: 24px;
    width: 24px;
    background: url(./images/c_b.svg);
    transition: .15s;
}

.custom-size-input button {
    display: flex;
}

.custom-size-input button:nth-child(3)::after {
    content: "";
    display: block;
    height: 24px;
    width: 24px;
    background: url(./images/x_b.svg);
    transition: .15s ease;
}

.custom-size-input button:nth-child(3):hover::after {
	background: url(./images/x_w.svg);
}

.custom-size-input button:nth-child(2):hover::after {
	background: url(./images/c_w.svg);
}

button.ui-button.ui-corner-all.ui-widget.ui-button-icon-only.ui-dialog-titlebar-close:active {
    opacity: .6;
}

button.ui-button.ui-corner-all.ui-widget.ui-button-icon-only.ui-dialog-titlebar-close:hover::before {
    transform: rotate(180deg) translateX(4px);
}

button.ui-button.ui-corner-all.ui-widget.ui-button-icon-only.ui-dialog-titlebar-close::before {
    transition: .3s ease;
}

.mktoForm input div {
    color: red !important;
}



.mktoForm input:hover {
    border: 1px solid #002C3F;
}

html .mktoForm input {
  
    padding: 24px !important;
    font-family: "Avenir LT W04_45 Book" !important;
    color: #0A0A0A !important;
    font-weight: 500 !important;
    font-size: 19px !important;
    line-height: 23px !important;
    letter-spacing: -0.02em !important;
}


html .mktoForm input::-webkit-input-placeholder {
    color: #ABB8C7 !important;
}

html .mktoForm input:focus-visible {
    border: 2px solid #0092D1;
    padding: 23px;
    box-shadow: 0px 0px 0px 4px rgb(155 225 255);
    outline: 0;
    border: 2px solid #0092D1;
    padding: 23px !important;
    box-shadow: 0px 0px 0px 4px rgb(155 225 255);
    outline: 0;
}

html .mktoForm select {
    padding: 24px !important;
    font-family: "Avenir LT W04_45 Book" !important;
    color: #0A0A0A !important;
    font-weight: 500 !important;
    font-size: 19px !important;
    line-height: 23px !important;
    letter-spacing: -0.02em !important;
}

html .mktoForm select:hover {
    cursor: pointer;
    border: 1px solid #002C3F;
}

html .mktoForm select::-webkit-input-placeholder {
    color: #ABB8C7 !important;
}

html .mktoForm select:focus-visible {
    border: 2px solid #0092D1;
    padding: 23px;
    box-shadow: 0px 0px 0px 4px rgb(155 225 255);
    outline: 0;
    border: 2px solid #0092D1;
    padding: 23px !important;
    box-shadow: 0px 0px 0px 4px rgb(155 225 255);
    outline: 0;
}


html .mktoForm textarea {
    padding: 24px !important;
    font-family: "Avenir LT W04_45 Book" !important;
    color: #0A0A0A !important;
    font-weight: 500 !important;
    font-size: 19px !important;
    line-height: 23px !important;
    letter-spacing: -0.02em !important;
    min-height: 150px;
}

html .mktoForm textarea:hover {
    cursor: text;
    border: 1px solid #002C3F;
}

html .mktoForm textarea::-webkit-input-placeholder {
    color: #ABB8C7 !important;
}

html .mktoForm textarea:focus-visible {
    border: 2px solid #0092D1;
    padding: 23px;
    box-shadow: 0px 0px 0px 4px rgb(155 225 255);
    outline: 0;
    border: 2px solid #0092D1;
    padding: 23px !important;
    box-shadow: 0px 0px 0px 4px rgb(155 225 255);
    outline: 0;
}

html .mktoForm label.mktoLabel {
    width: 100% !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 16px;
    line-height: 24px;
    font-family: "Avenir LT W04_45 Book" !important;
    color: #000;
    display: flex;
    flex-direction: row;
    padding-bottom: 6px;
    padding-top: 0;
}

.mktoForm span.mktoButtonWrap.mktoNative {
    margin-left: 0px !important;
    width: 100%;
}

.mktoForm span.mktoButtonWrap.mktoNative button {
    border: 1px solid #0A0A0A;
    padding: 24px;
    background: transparent;
    text-align: left;
    font-size: 18px;
    line-height: 40px;
    font-family: "Avenir LT W04_65 Medium";
    color: #0A0A0A;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transition: .15s ease;
    align-items: center;
    width: 100%;
    display: flex;
}

.mktoForm span.mktoButtonWrap.mktoNative button:hover {
    background: #0A0A0A;
    color: #fff;
}

.mktoForm span.mktoButtonWrap.mktoNative button::after {
    background: url(./images/quote.svg);
}

.mktoForm span.mktoButtonWrap.mktoNative button::after {
    transition: .15s ease;
}
.mktoForm span.mktoButtonWrap.mktoNative button::after {
    content: "";
    display: block;
    height: 24px;
    width: 24px;
    transition: .15s ease;
}

.mktoButtonRow {
    width: 100%;
}

.mktoForm span.mktoButtonWrap.mktoNative button:hover::after {
    background: url(./images/quote_white.svg);
}

label#LbloPTINLegal {
    width: calc(100% - 32px) !important;
    float: right;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #5C7289;

}

label#LbloPTINLegal:hover {
    cursor: pointer;
}

.mktoLogicalField.mktoCheckboxList.mktoHasWidth {
    width: 32px !important;
    float: left;
    display: block;
}

.mktoLogicalField.mktoCheckboxList.mktoHasWidth input:hover {
    cursor: pointer;
}

.mktoForm span.mktoButtonWrap.mktoNative button {
    margin-top: 16px;
}

.thank-you .left-image {
	background: url('https://www.soundsbeautiful.com/media/97/da/c8/1641475339/formpage-meeting-1.jpg');
}

body.thanks {
    height: 100%;
    width: 100%;
    float: left;
}

.thank-you {
    height: 100%;
    float: left;
    width: 100%;
    display: flex;
}

.thank-you .left-image {
    width: 33.33%;
    background-size: cover;
    background-position: center;
    min-height: 300px;
}

.right-copy {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.thank-you .right-copy {
    width: 66.66%;
    padding: 32px;
}

.thank-you .right-copy h1, .thank-you .right-copy h2 {
    margin: 0;
}

.thank-you .right-copy h1 {
    margin-bottom: 16px;
}

.thank-you .right-copy h2 {
    font-size: 23px;
    line-height: 28px;
    font-family: "Avenir LT W04_65 Medium";
    color: #0A0A0A;
    font-weight: 500;
}








.property-choices.image-upload .property-title::before {
    content: "";
}

.property-choices.image-upload .property-title {
    text-transform: initial;
}

.image-upload .property-choice::before {
    display: none;
}

.image-upload .property-choice {
border: 1px solid #0A0A0A;
    padding: 24px;
    background: transparent;
    text-align: left;
    font-size: 18px;
    line-height: 40px;
    font-family: "Avenir LT W04_65 Medium";
    color: #0A0A0A;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transition: .15s ease;
    align-items: center;    
}

.image-upload .property-choice::after {
content: "";
    display: block;
    height: 24px;
    width: 24px;
	background: url(./images/cad.svg);
    transition: .15s ease;
}

.image-upload .property-choice:hover::after {
    background: url(./images/cad_white.svg);
}


div#prop-upload-image {
    grid-template-columns: 1fr 1fr;
    grid-template-columns: repeat(1, 1fr);
}

.image-upload .property-choice:hover {
    background: #0A0A0A;
    color: #fff;
}

.image-upload .post {
    color: #5C7289;
    font-size: 14px;
    line-height: 17px;
    font-family: "Avenir LT W04_65 Medium";
    letter-spacing: -0.02em;
    transition: .15s ease;
}

.image-upload .property-choice {
    display: flex;
    flex-direction: row;
}

.image-upload .property-choice .pre {
    width: fit-content !important;
}

.image-upload .property-choice .post {
    flex: 1;
    text-align: right;
    padding-right: 15px;
}

.image-upload .property-choice:hover .post {
    color: #fff;
}

div#upload-image {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    left: unset !important;
    width: 40% !important;
    display: block !important;
    bottom: 0 !important;
    background: #fff;
    transform: translateX(100%);
    transition: .3s ease;
}

div#upload-image.open {
    transform: translateX(0%);
    transition: .3s ease;
}



div#upload-image {
    padding: 32px;
}

#upload-image h2 {
    margin-top: 32px;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: -0.02em;
    color: #000000;
    font-family: "Avenir LT W04_65 Medium";
    font-weight: 500;
    margin-bottom: 18px;
}

#upload-image p {
    margin-bottom: 32px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: #000000;
    font-family: "Avenir LT W04_45 Book";
}

div#upload-step-1 div.upload-side-option {
    border: 1px solid #0A0A0A !important;
    padding: 24px;
    background: transparent;
    text-align: left;
    font-size: 18px;
    line-height: 40px;
    font-family: "Avenir LT W04_65 Medium";
    color: #0A0A0A;
    width: 100% !important;
    display: flex !important;
    justify-content: space-between !important;
    transition: .15s ease;
    align-items: center;
    height: unset !important;
    margin-bottom: 16px;
    margin-right: 0 !important;
}



div#upload-step-1 div.upload-side-option:hover {
    cursor: pointer;
      background: #0A0A0A;
    color: #fff;
}

.drop-area {
    border: 2px solid #ccc;
    background-color: #f5f5f5;
    transition: background-color 0.3s;
	height: 100px;
}

.dragover {
    background-color: #e0e0e0;
}


svg.upload-image {
    height: 24px !important;
    width: auto;
}

input#file-upload > input {
    display: none !important;
}

input#file-upload-button {
    opacity: 0 !important;
    display: none !important;
    text-indent: -999px;
}

form#file-upload-form {
    position: relative;
    height: 220px;
}

.drop-area {
    width: 100%;
    position: absolute;
    left: 0;
    top: 20px;
    background: transparent;
}

.upl-dsgn {
    position: absolute;
    top: 20px;
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.upl-dsgn p {
    margin: 0 !important;
}

button#file-upload-button {
    position: absolute;
    bottom: -102px;
    right: 0;
}



button#upload-step-2-to-1 {border: 1px solid #0A0A0A;padding: 24px;background: transparent;text-align: left;font-size: 18px;line-height: 40px;font-family: "Avenir LT W04_65 Medium";color: #0A0A0A;width: calc(50% - 6px);margin-top: 12px;display: flex;justify-content: space-between;transition: .15s ease;align-items: center;}

button#upload-step-2-to-1::after {
content: "";
    display: block;
    height: 24px;
    width: 24px;
	background: url(./images/cad.svg);
    transition: .15s ease;
}

button#upload-step-2-to-1:hover::after {
    background: url(./images/cad_white.svg);
}

button#upload-step-2-to-1:hover {
    background: #0A0A0A;
    color: #fff;
    cursor: pointer;
}





#file-upload-button {border: 1px solid #0A0A0A;padding: 24px;background: transparent;text-align: left;font-size: 18px;line-height: 40px;font-family: "Avenir LT W04_65 Medium";color: #0A0A0A;width: calc(50% - 6px);margin-top: 12px;display: flex;justify-content: space-between;transition: .15s ease;align-items: center;}
#file-upload-button::after {
content: "";
    display: block;
    height: 24px;
    width: 24px;
	background: url(./images/cad.svg);
    transition: .15s ease;
}

#file-upload-button:hover::after {
    background: url(./images/cad_white.svg);
}

#file-upload-button:hover {
    background: #0A0A0A;
    color: #fff;
    cursor: pointer;
}

input#file-upload {
    height: 200px;
    z-index: 999;
}

#upload-step-2 p {
    margin: 0;
}

button#upload-step-2-to-1 {
    flex-direction: row-reverse;
    display: flex;
    justify-content: flex-end;
}

button#upload-step-2-to-1::after {
    transform: rotate(180deg);
    margin-right: 15px;
}

.drop-area {
    height: 200px;
    border: 1px solid #CAD2DB;
    z-index: 9999;
    transition: .15s ease;
}

.drop-area:hover {
    cursor: pointer;
    border: 1px solid #002C3F;
}

#upload-step-2 p {
    margin: 0;
}

button#upload-step-2-to-1 {
    flex-direction: row-reverse;
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
}

button#upload-step-2-to-1::after {
    transform: rotate(180deg);
    margin-right: 15px;
}

.drop-area {
    height: 200px;
    border: 1px solid #CAD2DB;
    z-index: 9999;
    transition: .15s ease;
    top: 32px;
}

.drop-area:hover {
    cursor: pointer;
    border: 1px solid #002C3F;
}

.upl-dsgn {
    top: 32px;
}

button#file-upload-button {
    bottom: -114px;
}

div#upload-step-2 li {
	font-size: 18px;
    font-style: normal;
    line-height: 26px;
    font-weight: 400;
    font-family: "Avenir LT W04_45 Book";
}

div#upload-step-2 p {
    font-size: 18px;
    font-style: normal;
    line-height: 26px;
}

.step-indicator li::after {
    content: "";
    display: block !important;
    height: 4px;
    width: 100%;
    background: #F0F2F5;
}

.step-indicator.s1 li:nth-child(1)::after {
    background: #ABB8C7;
}

.step-indicator li p {
    margin-bottom: 2px !important;
    font-family: "Avenir LT W04_65 Medium" !important;
    font-size: 16px !important;
    line-height: 24px !important;
}

.upl-dsgn p {
    font-size: 18px;
    font-style: normal;
    line-height: 26px;
    font-family: "Avenir LT W04_65 Medium";
    text-align: center;
}

.upl-dsgn p {
    font-size: 18px;
    font-style: normal;
    line-height: 22px !important;
    font-family: "Avenir LT W04_65 Medium" !important;
}

html p.supported-files {
    margin-top: 12px !important;
    font-size: 16px !important;
    font-style: normal;
    line-height: 24px;
    color: #5C7289 !important;
}

#file-upload-form p b {
    text-decoration: underline;
    color: #00638E;
}

svg.upload-image {
    height: 32px !important;
}

svg.upload-image path {
    fill: #002C3F;
}

div#upload-step-2 li::before {
    content: "";
    background: url();
    height: 18px;
    width: 18px;
    background-size: cover;
}

div#upload-step-2 li.hdn::before {
display: none;
}

div#upload-step-2 li:not(.hdn)::before {
    content: "";
    width: 16px;
    height: 16px;
    background: url(./images/selector_mt.svg);
    background-size: cover;
    transition: .15s ease;
}

div#upload-step-2 li.yes::before {
    content: "";
    width: 16px;
    height: 16px;
    background: url(./images/ye.svg);
    background-size: cover;
    transition: .15s ease;
}


div#upload-step-2 li.no::before {
    content: "";
    width: 16px;
    height: 16px;
    background: url(./images/no.svg);
    background-size: cover;
    transition: .15s ease;
}




.upl-dsgn {
    z-index: 9999;
    position: absolute;
    left: 1px;
    right: 1px;
    bottom: 1px;
    margin-top: 1px;
    background: white;
    pointer-events: none;
    transition: .15s ease;
    width: calc(100% - 2px);
    height: 198px;
}


#file-upload-form p b {
    font-weight: 400;
}

html p.supported-files {
    font-size: 14px !important;
    margin-top: 8px !important;
}

.hdn {
    color: #5C7289 !important;
    font-size: 14px !important;
    line-height: 120% !important;
}

.upl-dsgn.woooo {
    background: #CDF0FF;
}

div#upload-step-2 li {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 6px;
	transition: .15s ease;
}

.hdn {
    padding-left: 22px;
    margin-bottom: 4px;
}


#upload-step-2 ul {
    padding-left: 6px;
}

#upload-step-2 a {
    color: #00638E;
}

label {
    font-size: 18px;
    line-height: 26px;
    color: #000000;
    font-family: "Avenir LT W04_45 Book";
}

.drop-area {
    transition: .15s ease;
}

.drop-area.woooo {
    background: rgb(155 225 255 / 50%);
}


.step-indicator ul {
    padding: 0;
    display: flex;
    justify-content: space-between;
	gap: 8px;
}

.step-indicator ul li {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #000000;
    font-family: "Avenir LT W04_45 Book";
    width: 100%;
}

/* .step-indicator ul li::before {
    content: "";
    height: 12px;
    width: 12px;
    border-radius: 18px;
    background: #aab2bb;
	margin-left: 20px;
} */

.step-indicator {margin-top: 48px;
                position: relative;
                }

/* .step-indicator::before {
    content: "";
    height: 4px;
    width: 100%;
    display: block;
    background: #CAD2DB;
    border-radius: 10px;
    position: absolute;
    z-index: -1;
    top: 4px;
} */

/* .step-indicator::after {
    content: "";
    height: 4px;
    width: 27px;
    display: block;
    background: #00638E;
    border-radius: 10px;
    position: absolute;
    z-index: -1;
    top: 4px;
    transition: .6s ease;
}

.step-indicator.s2::after {

    width: calc(50% - 88px);

}

.step-indicator.s3::after {
    width: calc(100%);

} */

.step-indicator.s2 li b {
    transition: .6 ease;
}

.step-indicator li p {
    margin: 0;
    color: #ABB8C7 !important;
}

.step-indicator.s2 li:nth-child(2) p, .step-indicator.s2 li:nth-child(1) p  {
    color: #000 !important;
}

/* .step-indicator.s2 ul li:nth-child(1)::before, .step-indicator.s2 ul li:nth-child(2)::before {
    background: #00638E;
} */

.step-indicator.s1 li:nth-child(1) p  {
    color: #000 !important;
}

/* .step-indicator.s1 ul li:nth-child(1)::before {
    background: #00638E;
}*/

.step-indicator.s3 li:nth-child(3) p, .step-indicator.s3 li:nth-child(2) p, .step-indicator.s3 li:nth-child(1) p  {
    color: #000 !important;
}

/* .step-indicator.s3 ul li:nth-child(1)::before, .step-indicator.s3 ul li:nth-child(2)::before, .step-indicator.s3 ul li:nth-child(3)::before {
    background: #00638E;
} */

.step-indicator.s3::after {

    width: calc(100% - 179px);

}


















div#canvas-wrapper {
    position: absolute;
    left: -60vw;
    top: 0;
    background: #f7f9fa;
    display: flex;
    justify-content: center;
    height: 100vh;
    width: calc(100vw - 40vw);
    align-items: center;
}

div#upload-image.open {
    z-index: 99;
}


/* The element to apply the animation to */
div#canvas-wrapper {
  animation-name: cwrpr;
  animation-duration: .6s;
}


@keyframes cwrpr {
  from {opacity: 0;}
  to {opacity: 1;}
}


input[type=range] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type=range]:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type=range]::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent; 
  border-color: transparent;
  color: transparent;
}



/* Special styling for WebKit/Blink */
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 13px;
  width: 13px;
  border-radius: 12px;
  background: #00638E;
  cursor: pointer;
  margin-top: -5px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
}

/* All the same stuff for Firefox */
input[type=range]::-moz-range-thumb {
   height: 13px;
  width: 13px;
  border-radius: 12px;
  background: #00638E;
  cursor: pointer;
}

/* All the same stuff for IE */
input[type=range]::-ms-thumb {
    height: 13px;
  width: 13px;
  border-radius: 12px;
  background: #00638E;
  cursor: pointer;
}








input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: #E1E5EB;
  border-radius: 4px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #D6DADF;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: #E1E5EB;
  border-radius: 4px;
}

input[type=range]::-ms-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: #E1E5EB;
  border-radius: 4px;
}
input[type=range]::-ms-fill-lower {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: #E1E5EB;
  border-radius: 4px;
}
input[type=range]:focus::-ms-fill-lower {
  background: #D6DADF;
}
input[type=range]::-ms-fill-upper {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: #E1E5EB;
  border-radius: 4px;
}
input[type=range]:focus::-ms-fill-upper {
  background: #D6DADF;
}






.ali {
    display: flex;
    gap: 12px;
    justify-content: flex-start;
}

.al-in {
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 0;
}

input#scaleSlider::before {
    content: "";
    display: block;
    height: 16px;
    width: 16px;
    background: url(./images/min.svg);
    background-size: cover;
    background-position: center;
}

input#scaleSlider {
    display: flex;
    gap: 16px;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
    max-width: 100%;
}

input#scaleSlider::after {
    content: "";
    display: block;
    width: 32px;
    height: 32px;
    background: url(./images/max.svg);
    background-size: cover;
    background-position: center;
}

.step-indicator label {
    margin-bottom: 32px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: #000000;
    font-family: "Avenir LT W04_45 Book";
}






.al-in button {
    background: transparent;
    border: 0;
    padding: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: #000000;
    font-family: "Avenir LT W04_45 Book";
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    width: 80px;
}

.al-in button::before {
    content: "";
    height: 32px;
    width: 32px;
    display: block;
}



button#alignLeftButton::before {
    background: url(./images/l.svg);
    background-size: cover;
}

button#alignCenterButton::before {    background: url(./images/c.svg);
    background-size: cover;
}
button#alignRightButton::before {    background: url(./images/r.svg);
    background-size: cover;
}

button#alignTopButton::before {    background: url(./images/t.svg);
    background-size: cover;
}
button#alignCenterVerticalButton::before {    background: url(./images/m.svg);
    background-size: cover;
}

button#alignBottomButton::before {    background: url(./images/b.svg);
    background-size: cover;
}

button#alignLeftButton:hover::before {
    background: url(./images/l_d.svg);
    background-size: cover;
}

button#alignCenterButton:hover::before {    background: url(./images/c_d.svg);
    background-size: cover;
}
button#alignRightButton:hover::before {    background: url(./images/r_d.svg);
    background-size: cover;
}

button#alignTopButton:hover::before {    background: url(./images/t_d.svg);
    background-size: cover;
}
button#alignCenterVerticalButton:hover::before {    background: url(./images/m_d.svg);
    background-size: cover;
}

button#alignBottomButton:hover::before {    background: url(./images/b_d.svg);
    background-size: cover;
}
.fill-in button {
    background: transparent;
    border: 0;
    padding: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: #000000;
    font-family: "Avenir LT W04_45 Book";
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    width: 80px;
        transition: .15s ease;
    color: #ABB8C7;
    gap: 10px;
}


button#setHeightButton::before {
    background: url(./images/h.svg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

button#setWidthButton::before {
    background: url(./images/w.svg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

button#setHeightButton:hover::before {
    background: url(./images/h_d.svg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

button#setWidthButton:hover::before {
    background: url(./images/w_d.svg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
.control-wrap {
    display: flex;
    flex-direction: row;
    padding-bottom: 32px;
    gap: 12px;
    justify-content: flex-start;
}

.fill-in {
    display: flex;
    flex-direction: row;
}

.align-wrap {
    flex: 2;
}
.fill-in button::before {
    content: "";
    height: 32px;
    width: 32px;
    display: block;
}

.fill-in button:hover {
    cursor: pointer;
    color: #000;
}

.section-head {
	margin-bottom: 16px !important;
}

.al-in button {
    transition: .15s ease;
    color: #ABB8C7;
    gap: 10px;
}

.al-in button:hover {
    cursor: pointer;
    color: #000;
}


canvas#container-canvas {
    box-shadow: 0px 20px 56px 0px rgba(0, 0, 0, 0.29);
    outline: 3px solid #29333D;
    outline-offset: 1px;
}


button#apply-to-mesh-button {border: 1px solid #0A0A0A;padding: 24px;background: transparent;text-align: left;font-size: 18px;line-height: 40px;font-family: "Avenir LT W04_65 Medium";color: #0A0A0A;width: calc(50% - 6px);margin-top: 12px;display: flex;justify-content: space-between;transition: .15s ease;align-items: center;}
button#apply-to-mesh-button::after {
content: "";
    display: block;
    height: 24px;
    width: 24px;
	background: url(./images/cad.svg);
    transition: .15s ease;
}

button#apply-to-mesh-button:hover::after {
    background: url(./images/cad_white.svg);
}

button#apply-to-mesh-button:hover {
    background: #0A0A0A;
    color: #fff;
    cursor: pointer;
}


canvas#container-canvas {
    box-shadow: 0px 20px 56px 0px rgba(0, 0, 0, 0.29);
    outline: 3px solid #29333D;
    outline-offset: 1px;
    z-index: 9999;
}

button#apply-to-mesh-button {border: 1px solid #0A0A0A;padding: 24px;background: transparent;text-align: left;font-size: 18px;line-height: 40px;font-family: "Avenir LT W04_65 Medium";color: #0A0A0A;width: calc(50% - 6px);margin-top: 12px;display: flex;justify-content: space-between;transition: .15s ease;align-items: center;}
button#apply-to-mesh-button::after {
content: "";
    display: block;
    height: 24px;
    width: 24px;
	background: url(./images/cad.svg);
    transition: .15s ease;
}

button#apply-to-mesh-button:hover::after {
    background: url(./images/cad_white.svg);
}

button#apply-to-mesh-button:hover {
    background: #0A0A0A;
    color: #fff;
    cursor: pointer;
}

.upload-side-option::after {
	content: "";
    display: block;
    height: 26px;
    width: 40px;
    background: black;
    transition: .15s ease;
}


.upload-side-option.vr::after {
	content: "";
    display: block;
    height: 26px;
    width: 40px;
	background: url(./images/vr.svg);
    transition: .15s ease;
}

.upload-side-option.ach::after {
	content: "";
    display: block;
    height: 26px;
    width: 40px;
	background: url(./images/ach.svg);
    transition: .15s ease;
}

.panpanpan .upload-side-option.ach::after {
	content: "";
    display: block;
    height: 26px;
    width: 40px;
	background: url(./images/vr.svg);
    transition: .15s ease;
}
.panpanpan .upload-side-option.ach:hover::after {
	background: url(./images/vr_w.svg);
}



.panpanpan .upload-side-option.ach {text-indent: -9999px;}



.panpanpan .upload-side-option.vr {text-indent: -9999px;}



.panpanpan div#upload-step-1 div.upload-side-option.vr, .panpanpan div#upload-step-1 div.upload-side-option.bei {
    display: none !important;
}


.upload-side-option.bei::after {
	content: "";
    display: block;
    height: 26px;
    width: 40px;
	background: url(./images/bei.svg);
    transition: .15s ease;
}

.upload-side-option.vr:hover::after {
	background: url(./images/vr_w.svg);
}

.upload-side-option.ach:hover::after {
	background: url(./images/ach_w.svg);
}

.upload-side-option.bei:hover::after {
	background: url(./images/bei_w.svg);
}

.instruction-placeholder {
	display: block;
	background: url(./images/dragndrop.svg);
	height: 166px;
	width: 100%;
	background-position: center;
	background-size: contain;
    background-repeat: no-repeat;
    background-color: #f7f9fa;
}

canvas#container-canvas:hover {cursor: grab;}

canvas#container-canvas:active {cursor: grabbing;}




button#upload-step-3-to-2 {border: 1px solid #0A0A0A;padding: 24px;background: transparent;text-align: left;font-size: 18px;line-height: 40px;font-family: "Avenir LT W04_65 Medium";color: #0A0A0A;width: calc(50% - 6px);margin-top: 12px;display: flex;justify-content: space-between;transition: .15s ease;align-items: center;}

button#upload-step-3-to-2::after {
content: "";
    display: block;
    height: 24px;
    width: 24px;
	background: url(./images/cad.svg);
    transition: .15s ease;
}

button#upload-step-3-to-2:hover::after {
    background: url(./images/cad_white.svg);
}

button#upload-step-3-to-2:hover {
    background: #0A0A0A;
    color: #fff;
    cursor: pointer;
}


button#upload-step-3-to-2 {
    flex-direction: row-reverse;
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
}

button#upload-step-3-to-2::after {
    transform: rotate(180deg);
    margin-right: 15px;
}

html svg.upload-image, html svg.uploaded-image {
    height: 48px !important;
}

html svg.upload-image path, html svg.uploaded-image path {
    fill: #0A0A0A !important;
}

html svg.upload-image.hide, html svg.uploaded-image.hide {
    display: none !important;
}

button#upload-step-3-to-2 {
    width: calc(50% - 6px);
    float: left;
    margin-top: 12px;
    margin-right: 12px;
}

button#file-upload-button { pointer-events: none; opacity: .3; } button#file-upload-button.allowed { pointer-events: auto; opacity: 1; }



.kruisje.close-img-upload {
    height: 28px;
    position: absolute;
    right: 32px;
}

.kruisje.close-img-upload button {
    height: 28px;
    width: 28px;
    border: 0;
    background: 0;
    cursor: pointer;
}

.kruisje.close-img-upload button::before {
    content: "";
    background: url(./images/x_b.svg);
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
    height: 24px;
    width: 24px;
    transform: rotate(180deg);
    margin-right: 12px;
	transition: .15s ease;
    
}



button.property-choice.upload-image-remove .pre {
    display: none;
}

button.property-choice.upload-image-remove::after {
    content: "";
    display: block;
    height: 24px;
    width: 24px;
    background: url(./images/x_b.svg);
    transition: .15s ease;
}

button.property-choice.upload-image-remove:hover::after {
    content: "";
    display: block;
    height: 24px;
    width: 24px;
    background: url(./images/x_w.svg);
    transition: .15s ease;
}

.kruisje.close-img-upload button:hover::before {
	opacity: .6;
}

.awesome {
    position: fixed;
    bottom: 96px;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    height: fit-content;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: fit-content;
    min-width: 360px;
    background: #ECFDF5;
    padding: 24px;
    font-family: "Avenir LT W04_65 Medium" !important;
    gap: 12px;
	z-index: 9;
} 

.awesome::before {
    content: "";
    height: 24px;
    width: 24px;
    background: url(./images/progresscheckbox.svg);
    background-size: 25px;
	background-repeat: no-repeat;
	background-position: center;
    display: block;
}

.awesome::after {
    content: "";
    height: 4px;
    width: 0%;
    position: absolute;
    background: #34D399;
    bottom: 0;
    left: 0;
    animation-name: toaster;
    animation-duration: 8s;
    animation-timing-function: linear;
}

@keyframes toaster {
  from {width: 100%}
  to {width: 0%}
}

.helper {
    bottom: 0;
    left: 0;
    right: 0;
    width: fit-content;
    margin: 0 auto 32px !important;
    position: absolute;
}

.step-indicator li::after {
    content: "";
    display: block !important;
    height: 4px;
    width: 100%;
    background: #F0F2F5;
}

.step-indicator.s1 li:nth-child(1)::after {
    background: #ABB8C7;
}

.step-indicator li p {
    margin-bottom: 2px !important;
    font-family: "Avenir LT W04_65 Medium" !important;
    font-size: 16px !important;
    line-height: 24px !important;
}

.step-indicator.s2 li:nth-child(2)::after {
    background: #ABB8C7;
}

.step-indicator.s2 li:nth-child(1)::after {
    background: #000;
}


.step-indicator.s3 li:nth-child(3)::after {
    background: #ABB8C7;
}

.step-indicator.s3 li:nth-child(2)::after {
    background: #000;
}

.step-indicator.s3 li:nth-child(1)::after {
    background: #000;
}

.step-indicator.s3 li:nth-child(3) p, .step-indicator.s3 li:nth-child(2) p, .step-indicator.s3 li:nth-child(1) p {
    color: #000 !important;
}

/* Psudo content and translations */

.property-title::before {
    content: var(--translated-content-1, "Choose your ");
	text-transform: initial;
}

.property-subtitle::before {
    content: var(--translated-content-2, "Current: ");
}

.container-lamella_wall .ppr-ROCKFON_LAM_GSlat .property-choice:nth-child(2) > div::before, .container-lamella_wall .ppr-ROCKFON_LAM_SlatType .property-choice:nth-child(2) > div::before {
    content: var(--translated-content-3, "Narrow");
}

.container-lamella_wall .ppr-ROCKFON_LAM_GSlat .property-choice:nth-child(3) > div::before, .container-lamella_wall .ppr-ROCKFON_LAM_SlatType .property-choice:nth-child(3) > div::before {
    content: var(--translated-content-4, "Medium");
}

.container-lamella_wall .ppr-ROCKFON_LAM_GSlat .property-choice:nth-child(4) > div::before, .container-lamella_wall .ppr-ROCKFON_LAM_SlatType .property-choice:nth-child(4) > div::before {
    content: var(--translated-content-5, "Wide");
}

.container-lamella_wall .ROCKFON_LAM_Slats_Color .property-choice:nth-child(2) > div::before {
    content: var(--translated-content-6, "Light wood veneer");
}

.container-lamella_wall .ROCKFON_LAM_Slats_Color .property-choice:nth-child(3) > div::before {
    content: var(--translated-content-7, "Natural wood veneer");
}

.container-lamella_wall .ROCKFON_LAM_Slats_Color .property-choice:nth-child(4) > div::before {
    content: var(--translated-content-8, "Dark wood veneer");
}

.container-hub .ppr-ROCKFON_HUB_Panel_Size .property-choice:nth-child(2) .property-choice-text-wrap::before {
    content: var(--translated-content-9, "Small Hub");
}

.container-hub .ppr-ROCKFON_HUB_Panel_Size .property-choice:nth-child(3) .property-choice-text-wrap::before {
    content: var(--translated-content-10, "Medium Hub");
}

.container-hub .ppr-ROCKFON_HUB_Panel_Size .property-choice:nth-child(4) .property-choice-text-wrap::before {
    content: var(--translated-content-11, "Large Hub");
}

.container-hub .ppr-ROCKFON_HUB_Panel_Size .property-choice:nth-child(5) .property-choice-text-wrap::before {
    content: var(--translated-content-12, "Extra large Hub");
}

.ROCKFON_HUB_Panel_Color .property-choice:nth-child(2) > div::before {
    content: var(--translated-content-13, "Bright white");
    font-family: "Avenir LT W04_65 Medium";
    color: #0A0A0A;
    font-weight: 500;
    font-size: 19px;
    line-height: 23px;
    letter-spacing: -0.02em;
}

.ROCKFON_SCR_Width .property-choice > div::before {
    content: var(--translated-content-14, "Floor Small");
    font-family: "Avenir LT W04_65 Medium";
    color: #0A0A0A;
    font-weight: 500;
    font-size: 19px;
    line-height: 23px;
    letter-spacing: -0.02em;
    display: block;
}

.ROCKFON_SCR_Width .property-choice:nth-child(4) > div::before {
    content: var(--translated-content-15, "Floor Medium");
}

.ROCKFON_SCR_Width .property-choice:nth-child(3) > div::before {
    content: var(--translated-content-16, "Floor Large");
}


.ROCKFON_SCR_Frame_Color .property-choice > div::before {
    content: var(--translated-content-17, "Black");
    font-family: "Avenir LT W04_65 Medium";
    color: #0A0A0A;
    font-weight: 500;
    font-size: 19px;
    line-height: 23px;
    letter-spacing: -0.02em;
    display: block;
}

.ROCKFON_SCR_Frame_Color .property-choice > div::after {
    content: var(--translated-content-18, "Matt aluminium");
    color: #5C7289;
    font-size: 14px;
    line-height: 17px;
    font-family: "Avenir LT W04_65 Medium";
    letter-spacing: -0.02em;
}

.ROCKFON_SCR_Frame_Color .property-choice:nth-child(2) > div::after {
    content: var(--translated-content-19, "White aluminium");
}

.ROCKFON_SCR_Frame_Color .property-choice:nth-child(2) > div::before {
    content: var(--translated-content-20, "White");
}

.ROCKFON_SCR_Width .property-header::before {
    content: var(--translated-content-21, "Choose your size:");
}

button.but3::before {
    content: var(--translated-content-22, "Show all available colours");
    font-family: "Avenir LT W04_65 Medium";
    color: #0A0A0A;
    font-weight: 500;
    font-size: 19px;
    line-height: 23px;
    letter-spacing: -0.02em;
    flex-grow: 1;
    text-align: left;
}

button.but3.showme::before {
    content: var(--translated-content-23, "Show less");
}

.container-hdi .ROCKFON_SCR_Width .property-choice > div::before {
    content: var(--translated-content-24, "Divider Small");
}
.container-hdi .ROCKFON_SCR_Width .property-choice:nth-child(3) > div::before {
    content: var(--translated-content-25, "Divider Medium");
}
.container-hdi .ROCKFON_SCR_Width .property-choice:nth-child(4) > div::before {
    content: var(--translated-content-26, "Divider Large");
}
.container-pan .ROCKFON_SCR_Width .property-choice > div::before {
    content: var(--translated-content-27, "Portrait S");
}
.container-pan .ROCKFON_SCR_Width .property-choice:nth-child(3) > div::before {
    content: var(--translated-content-28, "Portrait L");
}
.container-pan .ROCKFON_SCR_Width .property-choice:nth-child(4) > div::before {
    content: var(--translated-content-29, "Landscape S");
}
.container-pan .ROCKFON_SCR_Width .property-choice:nth-child(5) > div::before {
    content: var(--translated-content-30, "Landscape M");
}
.container-pan .ROCKFON_SCR_Width .property-choice:nth-child(6) > div::before {
    content: var(--translated-content-31, "Landscape L");
}
.container-pan .ROCKFON_SCR_Width .property-choice:nth-child(7) > div::before {
    content: var(--translated-content-32, "Landscape XL");
}
.container-P286920 .ppr-ROCKFON_SEN_GAlignment .property-choice .property-choice-text-wrap::after, .container-P286920 .ppr-ROCKFON_SEN_GSHAPEMT .property-choice .property-choice-text-wrap::after {
    content: var(--translated-content-33, "Sub title");
    color: #5C7289;
    font-size: 14px;
    line-height: 17px;
    display: block;
	text-transform: initial;
}
.container-P286920 .ppr-ROCKFON_SEN_Color .property-choice .property-choice-text-wrap::after {
    content: var(--translated-content-34, "Organic surface");
    color: #5C7289;
    font-size: 14px;
    line-height: 17px;
    display: block;
    text-transform: initial;
}
.container-P286920 .ppr-ROCKFON_SEN_GFASTENINGTyp .property-choice .property-choice-text-wrap::after {
    content: var(--translated-content-35, "Spacer");
    color: #5C7289;
    font-size: 14px;
    line-height: 17px;
    display: block;
    text-transform: initial;
}
.ROCKFON_SCR_Width_i::before {
	content: var(--translated-content-36, "Custom width (min: 600mm, max: 4000mm)");
	color: #5C7289;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
}

.ROCKFON_SCR_Height_i::before {
	content: var(--translated-content-37, "Custom height  (min: 600mm, max: 1800mm)");
	color: #5C7289;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
}

.panpanpan .upload-side-option.ach::before {
    content: var(--translated-content-38, "Customize Canva - Front side") !important;
    text-indent: 0;
}
.panpanpan .upload-side-option.vr::before {
    content: var(--translated-content-39, "Customize Canva - Back side") !important;
    text-indent: 0;
}
button.property-choice.upload-image-remove::before {
    content: var(--translated-content-40, "Remove uploaded image");
    display: block;
    width: fit-content;
    margin-top: 0;
    background: none;
    line-height: 18px;
}


@media only screen and (max-width: 1400px) {
	.ui-dialog.ui-corner-all.ui-widget.ui-widget-content.ui-front.ui-draggable.ui-resizable {
    width: 50% !important;
}

	div#upload-image {
    width: 50% !important;
}

p.helper {
    padding: 0 32px;
}

   div#misc-buttons {
        left: calc(50% /2 - 139px ) !important;
    }
		
	
	#property-editor {
		width: 50%;
	}
	#viewer {
		width: 50%;	
	}
	
}


@media only screen and (max-width: 1200px) {
	
	div#upload-step-3 {
    height: calc(100% - 160px);
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 10px;
    width: calc(100% + 20px);
}

div#upload-step-2 {
    height: calc(100% - 160px);
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 10px;
    width: calc(100% + 20px);
}

div#upload-step-1 {
    height: calc(100% - 160px);
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 10px;
    width: calc(100% + 20px);
}

div#upload-step-2 br {
    display: none;
}

button#file-upload-button {
    bottom: -98px;
}

	button#upload-step-2-to-1 {
    width: 100%;
    margin-top: 110px;
}

button#file-upload-button {
    width: 100%;
}

button#upload-step-2-to-1, button#file-upload-button, div#upload-step-1 div.upload-side-option, .image-upload .property-choice {
    padding-top: 16px;
    padding-bottom: 16px;
}


div#upload-step-1 div.upload-side-option {
    margin-bottom: 12px;
}

.control-wrap {
    display: flex;
    flex-direction: column;
    padding-bottom: 12px;
}

	html .mktoForm input {
    padding: 16px 24px !important;
    height: 74px !important;
}

html .mktoForm input:focus-within {
    padding: 15px 23px !important;
}

div#upload-step-2 br {
    display: none;
}

button#file-upload-button {
    bottom: -98px;
}

p.section-head {
    margin-top: 12px;
    margin-bottom: 12px !important;
}

html .mktoForm select {
    padding: 16px 24px !important;
    height: 74px !important;
}

html .mktoForm select:focus-within {
    padding: 15px 23px !important;
}

.mktoForm span.mktoButtonWrap.mktoNative button {
    padding: 16px 24px !important;
    height: 74px !important;
}

input#oPTINLegal {
    height: unset !important;
}

.custom-size-input input {
    padding: 16px 24px !important;
    height: fit-content;
    min-height: 74px;
}

.custom-size-input input:focus-within {
    padding: 15px 23px !important;
}

.custom-size-input {
    height: fit-content;
    grid-template-rows: auto;
    margin-top: -86px;
}

.custom-size-input button {
    padding: 16px 24px;
}

.ROCKFON_SCR_Height_i .property-choice, .ROCKFON_SCR_Width_i .property-choice {
    padding: 16px 24px;
}

#export, .property .property-choices {
    grid-template-columns: 1fr 1fr;
    grid-template-columns: repeat(1, 1fr);
}

  button.export-item, .property-choice {
    padding-top: 16px;
    padding-bottom: 16px;
}

.property-choice.selected {
    padding-top: 15px;
    padding-bottom: 15px;
}



}



@media only screen and (max-width: 768px) {
	div#canvas-wrapper {
    position: relative;
    left: 0;
    width: 100%;
    max-height: 600px;
    order: -1 !important;
}

div#canvas-wrapper {
    flex-direction: column;
}

.ali {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
}
	
	div#upload-image.open {
    box-shadow: -10vw 0px 0px 20px rgb(0 0 0 / 50%);
}

div#upload-image {
    width: 90% !important;
    z-index: 99;
    transition: .3s ease, box-shadow .3s ease;
}
	
	.thank-you {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-evenly;
}

.thank-you .left-image {
    width: 100%;
    flex-grow: 2;
}

.thank-you .right-copy {
    width: 100%;
}
	
	.ui-dialog.ui-corner-all.ui-widget.ui-widget-content.ui-front.ui-draggable.ui-resizable.showmark {
    width: 90% !important;
    z-index: 99;
    box-shadow: -10vw 0px 0px 20px rgb(0 0 0 / 50%);
    transition: .3s ease, box-shadow .3s ease;
}
.ui-dialog.ui-corner-all.ui-widget.ui-widget-content.ui-front.ui-draggable.ui-resizable {
    width: 90% !important;
    z-index: 99;
    box-shadow: -10vw 0px 0px 20px transparent;
	transition: .3s ease, box-shadow 0s ease;
}

span#ui-id-2 {
    display: none;
}

input#persistence-share-link-input {
    width: 100%;
}

button#peristence-share-copy-button {
    position: initial;
    width: 100%;
    border: 1px solid #001620;
    margin-top: 16px;
    margin-bottom: 8px;
}



[aria-describedby="persistence-share"] div#persistence-share {
    padding: 16px;
}

.ui-dialog-titlebar.ui-corner-all.ui-widget-header.ui-helper-clearfix.ui-draggable-handle {
    right: calc(100% - 216px);
}

div#request-a-quote {
    padding: 16px;
}

[aria-describedby="downloads"] div#downloads {
    padding: 16px;
}

span#ui-id-3 {
    display: none;
}
	
	div#misc-buttons {
    display: none;
}
	
	div#property-editor {
    height: fit-content !important;
    overflow: auto;
}
	
	    div#misc-buttons {
    bottom: unset !important;
    left: calc(100% /2 - 139px ) !important;
}
  
#viewer {
    transition: .6s ease;
}


  

#viewer {
    width: 100%;
    z-index: 9;
    overflow: hidden;
    height: 300px;
    background: #f7f9fa;
}

  #viewer.jeclassnaamhier {
    height: 250px;
    transform: translateY(-50px);
    box-shadow: 0px -9px 18px rgba(14, 85, 116, 0.5), 0px 9px 18px rgba(0, 0, 0, 0.15);
	margin: 0 auto;
  }

#property-editor {
    width: 100%;
    margin-top: 300px;
}
canvas#container-canvas {
    transform: scale(.5);
    width: auto;
}
div#upload-step-3 h2 {
    margin-bottom: 0;
}

#upload-step-3 .s3buts {
    order: 2;
}

#upload-step-3 .s3buts button {
    width: 100%;
    margin-right: 0;
    padding-top: 16px;
    padding-bottom: 16px;
}

button#apply-to-mesh-button {
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
}

div#upload-step-3 {
    flex-direction: column;
}

}


@media only screen and (max-width: 480px) {
	#upload-image .step-indicator p {
    max-width: 70px;
}

.step-indicator ul li {
    align-items: flex-start;
}
	.step-indicator ul li p {
    line-height: 110% !important;
    font-size: 14px !important;
}

p.helper {
    font-size: 14px !important;
    line-height: 120% !important;
}

.mktoLabel strong {
    padding: 0;
}

.mktoForm {
    padding: 0;
}

}